import { Directive, ElementRef, inject, OnInit, RendererFactory2 } from "@angular/core"

@Directive({
  selector: "[e2eInherit], [inherit], e2e-root, e2e-breadcrumb, e2e-button, e2e-checkout, e2e-documentation, e2e-import, e2e-login-google-button, e2e-login-logged-in, e2e-login-logged-out, e2e-login, e2e-group-card, e2e-groups, e2e-item-details, e2e-item, e2e-items, e2e-products-page, e2e-sidebar, e2e-toolbar-banner, e2e-toolbar-menu, e2e-user-avatar, e2e-checkbox, e2e-number, e2e-radio, e2e-text, e2e-textarea",
  standalone: true
})
export class InheritDirective implements OnInit {
  private elementRef = inject(ElementRef)
  private rendererFactory = inject(RendererFactory2)

  ngOnInit() {
    const renderer = this.rendererFactory.createRenderer(null, null)
    renderer.setStyle(this.elementRef.nativeElement, "display", "contents")
  }

}
