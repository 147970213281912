import { ChangeDetectionStrategy, Component, computed, inject, input, signal } from "@angular/core"
import { Store } from "@ngrx/store"
import * as AppStore from "../../store"
import { CartActions } from "../../store"
import { ItemComponent } from "./item.component"
import { Group, Item } from "../../store/content/content.model"
import { CarouselModule } from "primeng/carousel"
import { FormsModule } from "@angular/forms"
import { InputNumberModule } from "primeng/inputnumber"
import { GroupImage } from "./group.model"
import { convertToImageObject } from "../../utilities/convert-to-image-object.utility"
import { InheritDirective } from "../../directives/inherit.directive"
import { HlmButtonDirective } from "@spartan-ng/ui-button-helm"
import { LetDirective } from "@ngrx/component"
import { CheckoutComponent } from "../checkout.component"
import { GroupService } from "./group.service"
import { environment } from "../../../environments/environment"

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    ItemComponent,
    CarouselModule,
    FormsModule,
    InputNumberModule,
    InheritDirective,
    HlmButtonDirective,
    LetDirective,
    CheckoutComponent,
  ],
  providers: [],
  standalone: true,
  template: `
    <div class="row">
      <div class="inline-block shadow-2 p-4 rounded flex-1" style="min-width: 500px">
        <div class="column" style="align-items: center; justify-content: center">
          <div
            style="text-align: center"
            (click)="resetCarousel()"
          >
            {{ group().title }}
          </div>
          <p-carousel
            [value]="imageObjects()"
            [numVisible]="1"
            [numScroll]="1"
            [circular]="true"
            [page]="carouselIndex()"
            (onPage)="carouselIndex.set($event.page || 0)"
          >
            <ng-template let-image pTemplate="item">
              <div class="border-1 rounded m-2 text-center py-5 px-3">
                <div class="w-full overflow-hidden aspect-square">
                  <img
                    [src]="image.itemImageSrc"
                    [alt]="image.alt"
                    class="shadow-2"
                    (error)="onImageLoadError(image)"
                  />
                </div>
              </div>
            </ng-template>
          </p-carousel>
        </div>
      </div>
      <div
        class="column"
        style="min-width: 400px; text-align: center; align-items: center; flex-grow: 1"
      >
        @if (groupItems().length > 4) {
          {{ groupItems().length }} options, scroll to see more
        }
        <div class="column" style="height: 200px; overflow: scroll">
          @for (item of groupItems(); track item.sku) {
            <e2e-item
              [item]="item"
              (click)="selectItem(item)"
            />
          }
        </div>
        Quantity
        <p-inputNumber
          inputId="horizontal"
          spinnerMode="horizontal"
          [step]="1"
          [min]="1"
          [showButtons]="true"
          buttonLayout="horizontal"
          decrementButtonClass="p-button-danger"
          incrementButtonClass="p-button-success"
          incrementButtonIcon="pi pi-plus"
          decrementButtonIcon="pi pi-minus"
          [inputStyle]="{ width: '60px', 'text-align': 'center' }"
          [ngModel]="quantity()"
          (ngModelChange)="quantity.set($event || 1)"
        ></p-inputNumber>
        <div>
          {{ price() | currency }}
        </div>
        <button
          hlmBtn
          style="color: white"
          variant="blue"
          size="medium"
          [disabled]="!quantity()"
          (click)="addToCart(selectedItem(), group(), quantity())"
        >
          Add To Cart
        </button>
        <e2e-checkout/>
      </div>
    </div>
  `,
  selector: "e2e-items",
})
export class ItemsComponent {
  private groupService = inject(GroupService)
  private store = inject(Store<AppStore.State>)

  // defaultImage = "https://storage.googleapis.com/" + environment.firebaseConfig.storageBucket + "/images/1420594609_132.jpg"

  groupItems = input.required<Item[]>()
  group = input.required<Group>()

  showAllImages = signal(true)
  selectedItem = signal<Item | undefined>(undefined)
  quantity = signal(1)
  price = computed(() => this.quantity() * (this.selectedItem()?.retail || 0))
  carouselIndex = signal(0)

  imageObjects = computed(() => !this.showAllImages() && this.groupItems()
      .find(groupItem => groupItem.sku === this.selectedItem()?.sku)
    && this.selectedItem()
    && this.selectedItem()?.images
      .map(image => convertToImageObject(image))
    || this.groupService.groupImageObjects().length
    && this.groupService.groupImageObjects()
    || [convertToImageObject("product-placeholder.svg", { source: "assets" })]
  )

  selectItem(selectedItem: Item) {
    this.showAllImages.set(false)
    if (!this.quantity()) {
      this.quantity.set(1)
    }
    if (selectedItem) {
      this.selectedItem.set(selectedItem)
      if (selectedItem.images.length) {
        this.carouselIndex.set(0)
      }
    }
  }

  addToCart(selectedItem: Item | undefined, group: Group | undefined, quantity: number) {
    if (selectedItem && group && quantity) {
      this.store.dispatch(CartActions.add({ item: selectedItem, group, quantity }))
    }
  }

  onImageLoadError(groupImage: GroupImage) {
    /**
     * Remove image from groupImages
     *
     * TODO: send error report to analytics
     console.log("missing image: ", groupImage.itemImageSrc)
     */
    /*
        this.groupImages = this.groupImages
          .filter(image => image.itemImageSrc !== groupImage.itemImageSrc)
        if (!this.groupImages.length) {
          this.groupImages = [convertToImageObject("product-placeholder.svg", { source: "assets" })]
        }
    */
  }

  resetCarousel() {
    this.carouselIndex.set(this.groupService.groupImageObjects().findIndex(item => {
      const filePath = this.selectedItem()?.images[this.carouselIndex()]
      return filePath && item.itemImageSrc.includes(filePath)
    }))
    this.showAllImages.set(true)
  }

}
