import { ChangeDetectionStrategy, Component, input, Input } from "@angular/core"
import { CommonModule } from "@angular/common"
import { Item } from "../../store/content/content.model"
import { ItemDetailsComponent } from "./item-details.component"
import { InheritDirective } from "../../directives/inherit.directive"

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    ItemDetailsComponent,
    InheritDirective,
  ],
  providers: [],
  standalone: true,
  template: `
    @if (itemInput(); as item) {
      <div class="row" style="flex-wrap: nowrap">
        @if (item.title) {
          <div>
            {{ item.title }}
          </div>
        }
        <!--        <div *ngIf="item.manufacturer">-->
        <!--          {{ item.manufacturer }}-->
        <!--        </div>-->
        @if (item.retail) {
          <div>
            <!--          {{ item.retail }}-->
            {{ item.retail | currency }}
          </div>
        }
        @if (item.unit) {
          <div>
            {{ item.unit }}
          </div>
        }
      </div>
    }
  `,
  selector: "e2e-item",
})
export class ItemComponent {
  itemInput = input.required<Item>({ alias: "item" })
}
