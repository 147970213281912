import { inject, Injectable } from "@angular/core"
import { Actions, createEffect, ofType } from "@ngrx/effects"
import { catchError, map, switchMap } from "rxjs/operators"
import { StripeActions } from "./stripe.actions"
import { CustomerDoc } from "./stripe.model"
import { doc, Firestore as AngularFirestore, getDoc, } from "@angular/fire/firestore"
import { Store } from "@ngrx/store"
import * as AppStore from "../index"
import { from, of } from "rxjs"

@Injectable()
export class StripeEffects {
  private actions$ = inject(Actions)
  private store = inject(Store<AppStore.State>)
  private afs = inject(AngularFirestore)

  getCustomerIdFromCustomersInFirestore = createEffect(() => this.actions$.pipe(
    ofType(StripeActions.getCustomerId),
    switchMap(({ afUser }) => {
      if (afUser?.uid) {
        return from(getDoc(doc(this.afs, "customers", afUser.uid)))
          .pipe(
            map(snapshot => {
              if (snapshot.exists()) {
                const firestoreCustomer = snapshot.data() as CustomerDoc
                return StripeActions.setCustomerId({ customerId: firestoreCustomer.stripeId })
              }
              return StripeActions.setCustomerId({ customerId: null })
            }),
            catchError(() => of(StripeActions.setCustomerId({ customerId: null }))),
          )
      }
      return of(StripeActions.setCustomerId({ customerId: null }))
    }),
  ))

}
