import { createFeatureSelector, createSelector } from "@ngrx/store"
import { FeatureKey, State } from "./user.model"

export const selectState = createFeatureSelector<State>(FeatureKey)

export const afUser = createSelector(selectState, state =>
  state.afUser)
export const userName = createSelector(selectState, state =>
  state.afUser?.displayName || "")
export const loggedIn = createSelector(selectState, state =>
  state.loggedIn)
export const loggedOut = createSelector(selectState, state =>
  state.loggedIn === false) // but not null
export const profileImagePhotoURL = createSelector(selectState, state =>
  state.afUser?.photoURL)
export const loading = createSelector(selectState, state =>
  state.loading)
