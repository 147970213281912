import { ChangeDetectionStrategy, Component, inject } from "@angular/core"
import { FormsModule } from "@angular/forms"
import { InputTextModule } from "primeng/inputtext"
import { LoginGoogleButtonComponent } from "./login-google-button.component"
import { AuthService } from "../../services/auth.service"
import { InheritDirective } from "../../directives/inherit.directive"
import { HlmButtonDirective } from "@spartan-ng/ui-button-helm"

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    FormsModule,
    InputTextModule,
    LoginGoogleButtonComponent,
    InheritDirective,
    HlmButtonDirective,
  ],
  standalone: true,
  template: `
    <e2e-login-google-button style="display: contents"></e2e-login-google-button>
    <div style="text-align: center">
      Or continue with email
    </div>
    <div>
      <div>
        Email address
      </div>
      <input
        placeholder="email address"
        pInputText
        type="email"
        style="width: 100%"
        [(ngModel)]="email"
      />
    </div>

    <div>
      <div style="display: flex; justify-content: space-between">
        <div>
          Password
        </div>
        <div
          style="color: #1890ff"
          (click)="passwordVisible = !passwordVisible"
        >
          {{ passwordVisible ? "hide" : "show" }}
        </div>
      </div>
      <input
        placeholder="password"
        pInputText
        type="password"
        style="width: 100%"
        [type]="passwordVisible ? 'text' : 'password'"
        [(ngModel)]="password"
      />
      <div style="color: #1890ff; height: 1rem">
        {{ !newAccount ? "Forgot your password?" : "" }}
      </div>
    </div>
    <div class="text-center text-white">
      <button
        hlmBtn
        variant="blue"
        size="medium"
        [disabled]="!email || !password"
        (click)="login(email, password)"
      >
        {{ newAccount ? "Sign Up (create new account)" : "Sign In with existing account" }}
      </button>
    </div>
    <div class="flex gap-2 align-items-center justify-content-center">
      <div>
        {{ newAccount ? "Already have an account?" : "Don't have an account?" }}
      </div>
      <button
        hlmBtn
        variant="ghost"
        size="medium"
        (click)="newAccount = !newAccount"
      >
        {{ newAccount ? "Sign In" : "Sign Up" }}
      </button>
    </div>
  `,
  selector: "e2e-login-logged-out",
})
export class LoginLoggedOutComponent {
  private authService = inject(AuthService)

  password = ""
  passwordVisible = false
  email = ""
  newAccount = false

  login(email: string, password: string) {
    if (email && password) {
      switch (this.newAccount) {
        case true:
          this.createUserWithEmailAndPassword(email, password)
          break
        case false:
          this.signInWithEmailAndPassword(email, password)
      }
    }
  }

  signInWithEmailAndPassword(email: string, password: string) {
    this.authService.signInWithEmailAndPassword(email, password)
      .then()
      .catch(error => {
        const errorCode = error.code
        const errorMessage = error.message
        switch (errorCode) {
          case "auth/user-not-found":
            /**
             * prompt user to create new account if they are sure of their email address
             */
            // this.createUserWithEmailAndPassword(email, password)
            break
          case "auth/invalid-email":
          case "auth/user-disabled":
            alert(errorMessage)
            break
          case "auth/wrong-password":
            /**
             * prompt user that they may have logged in using this email with a different provider such as Google Login
             */
            alert("Wrong password or wrong provider. Try changing the password or try logging in with Google instead.")
            break
          default:
            alert("unkown error: " + errorCode + " - " + errorMessage)
        }
        alert("all errors: " + errorCode + " - " + errorMessage)

      })
  }

  createUserWithEmailAndPassword(email: string, password: string) {
    this.authService.createUserWithEmailAndPassword(email, password)
      .then()
      .catch((error) => {
        // Handle Errors here.
        const errorCode = error.code
        const errorMessage = error.message
        switch (errorCode) {
          case "auth/weak-password":
            alert("The password is too weak.")
            break
          case "auth/email-already-in-use":
            /**
             * prompt the user to login with this email and password
             */
            alert(errorCode + " - " + errorMessage)
            this.signInWithEmailAndPassword(email, password)
            break
          default:
            alert(errorCode + " - " + errorMessage)
        }
      })
  }

  /*
    signInWithEmailAndPassword(email: string, password: string) {
      this.authService.signInWithEmailAndPassword(email, password)
        .then()
        .catch(error => {
          const event: ExceptionEvent = {
            eventName: EventName.EXCEPTION,
            eventParams: {
              class: this.constructor.name,
              function: "signInWithEmailAndPassword()",
              action: "this.authService.signInWithEmailAndPassword()",
              description: String(error),
              stack: error.stack,
              fatal: false
            }
          }
          this.analyticsService.sendEvent(event)
        })
    }

    createUserWithEmailAndPassword(email: string, password: string) {
      this.authService.createUserWithEmailAndPassword(email, password)
        .then()
        .catch(error => {
          const event: ExceptionEvent = {
            eventName: EventName.EXCEPTION,
            eventParams: {
              class: this.constructor.name,
              function: "createUserWithEmailAndPassword()",
              action: "this.authService.createUserWithEmailAndPassword()",
              description: String(error),
              stack: error.stack,
              fatal: false
            }
          }
          this.analyticsService.sendEvent(event)
        })
    }
  */

}
