import { ApplicationConfig, importProvidersFrom, isDevMode } from "@angular/core"
import { provideRouter, withEnabledBlockingInitialNavigation } from "@angular/router"
import { appRoutes } from "./app.routes"
import { environment } from "../environments/environment"
import { initializeApp, provideFirebaseApp } from "@angular/fire/app"
import { getFirestore, provideFirestore } from "@angular/fire/firestore"
import { getAuth, provideAuth } from "@angular/fire/auth"
import { BrowserAnimationsModule } from "@angular/platform-browser/animations"
import { ConfirmationService, MessageService } from "primeng/api"
import { BrowserModule, HammerModule } from "@angular/platform-browser"
import "hammerjs"
import { NavigationActionTiming, provideRouterStore, routerReducer, RouterState } from "@ngrx/router-store"
import { provideStore } from "@ngrx/store"
import { provideEffects } from "@ngrx/effects"
import { provideStoreDevtools } from "@ngrx/store-devtools"
import { HttpClientModule } from "@angular/common/http"
import * as AppStore from "./store"
import { ImportEffects } from "./store/import/import.effects"
import { UserEffects } from "./store/user/user.effects"
import { ContentEffects } from "./store/content/content.effects"
import { StripeEffects } from "./store/stripe/stripe.effects"
import { RouteSerializer } from "./store/router/route-serializer"
import { provideNgxStripe } from "ngx-stripe"
import { CartEffects } from "./store/cart/cart.effects"
import { getStorage, provideStorage } from "@angular/fire/storage"
// import { AuthTokenHttpInterceptorProvider } from "./http-interceptors/auth-token.interceptor"
// import { HttpErrorInterceptor } from "./http-interceptors/http-error.interceptor"
// import { ErrorHandlerService } from "./services/error-handler.service"

export const appConfig: ApplicationConfig = {
  providers: [
    importProvidersFrom(
      provideFirebaseApp(() => initializeApp(environment.firebaseConfig)),
      provideAuth(() => getAuth()),
      provideFirestore(() => getFirestore()),
      // importProvidersFrom(provideAnalytics(() => getAnalytics())),
      provideStorage(() => getStorage()),
      // importProvidersFrom(provideRemoteConfig(() => getRemoteConfig())),
      BrowserAnimationsModule,
      BrowserModule,
      HttpClientModule,
      HammerModule,
      // importProvidersFrom(provideFirestore(() => initializeFirestore(getApp(), { localCache: persistentLocalCache(/*settings*/ { tabManager: persistentMultipleTabManager() }) }))),
    ),
    provideNgxStripe(environment.stripeKey),
    provideRouter(appRoutes, withEnabledBlockingInitialNavigation()),
    // AuthTokenHttpInterceptorProvider,
    provideRouterStore({
      serializer: RouteSerializer,
      navigationActionTiming: NavigationActionTiming.PostActivation,
      routerState: RouterState.Minimal,
    }),
    provideEffects([
      CartEffects,
      ContentEffects,
      ImportEffects,
      UserEffects,
      StripeEffects,
    ]),
    provideStore({
      router: routerReducer,
      cart: AppStore.cartReducer,
      content: AppStore.contentReducer,
      user: AppStore.userReducer,
      stripe: AppStore.stripeReducer,
    }),
    { provide: ConfirmationService },
    { provide: MessageService },
    // {
    //   provide: ErrorHandler,
    //   useClass: ErrorHandlerService
    // },
    // {
    //     provide: HTTP_INTERCEPTORS,
    //     useClass: HttpErrorInterceptor,
    //     multi: true
    // },
    // { provide: "GroupContentService", useClass: ContentDataService },
    // { provide: "ItemContentService", useClass: ContentDataService },
    // { provide: "MenuContentService", useClass: ContentDataService },
    // { provide: "PageContentService", useClass: ContentDataService },
    provideStoreDevtools({
      maxAge: 25,
      logOnly: !isDevMode(),
      connectInZone: false
    }),
  ]
}
