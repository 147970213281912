import { computed, Directive, input, Input, signal } from '@angular/core';
import { hlm } from '@spartan-ng/ui-core';
import { cva, VariantProps } from 'class-variance-authority';
import { ClassValue } from 'clsx';

export const buttonVariants = cva(
  "whitespace-nowrap inline-flex gap-2 items-center justify-center font-medium transition-colors " +
  "focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 " +
  "disabled:opacity-50 disabled:pointer-events-none ring-offset-background",
  {
    variants: {
      variant: {
        default: "rounded-md text-primary-foreground bg-primary hover:bg-primary/90",
        destructive: "rounded-md bg-destructive/80 text-destructive-foreground hover:bg-destructive/100",
        outline: "rounded-md border border-input hover:bg-accent hover:text-accent-foreground",
        rtdugganOutlineMenu: "rounded-md justify-start no-underline font-bold text-gray-500 border border-gray-300 " +
          "border-solid hover:bg-accent hover:text-accent-foreground py-8",
        secondary: "rounded-md bg-secondary text-secondary-foreground hover:bg-secondary/80",
        ghost: "rounded-md hover:bg-accent hover:text-accent-foreground",
        link: "rounded-md underline-offset-4 hover:underline text-primary",
        gray: "rounded-md text-primary-foreground bg-gray-700/80 hover:bg-gray-700",
        green: "rounded-md text-primary-foreground bg-green-800/80 hover:bg-green-800",
        blue: "rounded-md text-primary-foreground bg-blue-500/80 hover:bg-blue-500",
        blueInputLabel: "rounded-md text-primary-foreground bg-blue-500/80 hover:bg-blue-500 p-0",
        orange: "rounded-md text-primary-foreground bg-orange-800/80 hover:bg-orange-800",
        red: "rounded-md text-primary-foreground bg-red-800/80 hover:bg-red-800",
        white: "rounded-md bg-white-800/80 hover:bg-white-800",
        redlineBookmarkRed: "rounded-[100%] bg-red-700 text-neutral-50 border border-blue-500", // #fafafa rgb(250, 250, 250)
        redlineBookmarkGreen: "rounded-[100%] bg-green-700 text-neutral-50", // #fafafa rgb(250, 250, 250)
      },
      size: {
        default: "h-10 py-2 px-4",
        defaultLabel: "p-0",
        outlineRtdugganMenu: "h-10 py-8 px-4",
        xs: "h-3 px-1 text-[8px]",
        extraSmall: "h-3 px-1 text-[8px]",
        sm: "h-9 px-3 text-sm",
        small: "h-6 px-3 text-sm",
        md: "h-8 px-2",
        medium: "h-8 px-2",
        mediumRound: "rounded-[100%] h-8 px-2",
        lg: "h-11 px-8",
        large: "h-11 px-8",
        icon: "h-10 w-10",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  },
)
export type ButtonVariants = VariantProps<typeof buttonVariants>;

@Directive({
	selector: '[hlmBtn]',
	standalone: true,
	host: {
		'[class]': '_computedClass()',
	},
})
export class HlmButtonDirective {
	_userClass = input<ClassValue>('', { alias: 'class' });
	private readonly _settableClass = signal<ClassValue>('');

	protected _computedClass = computed(() =>
		hlm(buttonVariants({ variant: this._variant(), size: this._size() }), this._settableClass(), this._userClass()),
	);

	setClass(value: ClassValue) {
		this._settableClass.set(value);
	}

	private readonly _variant = signal<ButtonVariants['variant']>('default');
	@Input()
	set variant(variant: ButtonVariants['variant']) {
		this._variant.set(variant);
	}

	private readonly _size = signal<ButtonVariants['size']>('default');
	@Input()
	set size(size: ButtonVariants['size']) {
		this._size.set(size);
	}
}
