import { ChangeDetectionStrategy, Component, input } from "@angular/core"
import { GroupView } from "./group.model"
import { RouterLink } from "@angular/router"
import { InheritDirective } from "../../directives/inherit.directive"

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    RouterLink,
    InheritDirective,
],
  providers: [],
  standalone: true,
  template: `
    @if (groupView(); as groupView) {
      <a
        style="display: inline-block; padding: 20px; border-radius: 8px; width: 100%; height: 100%; box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.1)"
        [routerLink]="groupView.menuItem.routerLink"
      >
        <div class="column" style="align-items: center; justify-content: center; height: 100%; width: 100%">
          @if (groupView.images[0]) {
            <div class="w-40 h-24 overflow-hidden">
              <img
                [alt]="groupView.title"
                [src]="groupView.images[0].itemImageSrc"
              />
            </div>
          }
          <div class="text-center">
            {{ groupView.title }}
          </div>
        </div>
      </a>
    }
  `,
  selector: "e2e-group-card",
})
export class GroupCardComponent {
  groupView = input.required<GroupView>()
}
