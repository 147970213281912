import { Component, inject, OnInit } from "@angular/core"
import { RouterModule } from "@angular/router"
import { Auth as AngularFireAuth, User as AngularFireUser } from "@angular/fire/auth"
import { Store } from "@ngrx/store"
import * as AppStore from "./store"
import { ToolbarMenuComponent } from "./components/toolbar-menu.component"
import { SidebarComponent } from "./components/sidebar/sidebar.component"
import { ToolbarBannerComponent } from "./components/toolbar-banner.component"
import { InheritDirective } from "./directives/inherit.directive"

@Component({
  imports: [
    RouterModule,
    SidebarComponent,
    ToolbarBannerComponent,
    ToolbarMenuComponent,
    InheritDirective,
],
  providers: [],
  standalone: true,
  template: `
    <e2e-toolbar-menu/>
    <e2e-toolbar-banner/>
    <div class="flex" style="padding: 10px; gap: 5px">
      <e2e-sidebar/>
      <div class="w-full">
        <router-outlet></router-outlet>
      </div>
    </div>
  `,
  selector: "e2e-root",
})
export class AppComponent implements OnInit {
  private afAuth = inject(AngularFireAuth)
  private store = inject(Store<AppStore.State>)

  ngOnInit() {
    this.store.dispatch(AppStore.ContentActions.init())
    this.store.dispatch(AppStore.CartActions.init())

    this.afAuth.onAuthStateChanged((payload) => {
      if (payload) {
        const afUser: Partial<AngularFireUser> = {
          emailVerified: payload.emailVerified,
          email: payload.email,
          displayName: payload.displayName,
          isAnonymous: payload.isAnonymous,
          phoneNumber: payload.phoneNumber,
          photoURL: payload.photoURL,
          providerId: payload.providerId,
          uid: payload.uid,
          tenantId: payload.tenantId,
        }
        this.store.dispatch(AppStore.UserActions.authStateChange({ afUser: afUser }))
        this.store.dispatch(AppStore.StripeActions.getCustomerId({ afUser: afUser }))
        // this.store.dispatch(AppStore.UserActions.authenticated({ afUser: afUser }))
      } else {
        this.store.dispatch(AppStore.UserActions.authStateChange({ afUser: null }))
        // this.store.dispatch(AppStore.UserActions.notAuthenticated())
      }
    })
  }

}
