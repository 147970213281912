import { computed, inject, Injectable } from "@angular/core"
import { RouteService } from "../../services/route.service"
import { Group, Groups, Item } from "../../store/content/content.model"
import { GroupImage, GroupView } from "./group.model"
import { idFromText } from "../../services/utils.service"
import { convertToImageObject } from "../../utilities/convert-to-image-object.utility"
import { sortByField } from "../../utilities/sort.utility"
import { FirestoreReadService } from "../../services/firestore-read.service"

@Injectable({
  providedIn: "root"
})
export class GroupService {
  private routeService = inject(RouteService)
  private firestoreReadService = inject(FirestoreReadService)

  group = computed(() => Object.values(this.firestoreReadService.groups_keyed())
    .find(group =>
      group.groups.length === this.routeService.pathSegments().length
      && group.groups
        .every((group, i) =>
          idFromText(group) === idFromText(this.routeService.pathSegments()[i])
        ))
  )

  groupItemsSorted = computed(() => sortByField("title", this.groupItems(),
    { byShoeSize: true, reverse: false }))

  private groupItems = computed(() => this.group()?.itemRefs
      .map(itemRef => this.firestoreReadService.items_map().get(itemRef))
      .filter(Boolean) as Item[]
    || []
  )

  private groupImages = computed(() =>
    Array.from(new Set([
      ...this.getGroupImages(this.group(), this.firestoreReadService.groups_keyed()),
      ...this.groupItemsSorted().flatMap(item => item.images)
    ]))
  )

  groupImageObjects = computed(() =>
    this.routeService.pathSegments().length && this.group()
    && this.groupImages().map(image => convertToImageObject(image))
    ||
    !this.routeService.pathSegments().length && !this.group()
    && Object.values(this.firestoreReadService.groups_keyed())
      .filter(group => group.groups.length === 1)
      // .filter(group => group.images.length)
      .flatMap(group => group.images.map(image => convertToImageObject(image)))
    || []
  )

  groupsViewSorted = computed(() => sortByField("title", this.groupsView()))

  private groupsView = computed(() =>
    this.routeService.pathSegments().length && this.group()?.groupRefs.length
    && this.group()?.groupRefs
      // .filter(groupRef => this.firestoreReadService.groups()[groupRef]?.itemRefs.length || this.firestoreReadService.groups()[groupRef].groupRefs.length)
      .map(groupRef => this.firestoreReadService.groups_map().get(groupRef))
      .filter(Boolean)
      .map(group => this.buildGroupVM(group as Group, this.groupImageObjects()))
      // .sort((a, b) => sort(a.title, b.title))
    ||
    (!this.routeService.pathSegments().length || !this.group())
    && Object.values(this.firestoreReadService.groups_keyed())
      .filter(group => group.groups.length === 1)
      // .filter(group => group.images.length)
      .flatMap(group => this.buildGroupVM(group, this.groupImageObjects()))
      // .sort((a, b) => sort(a.title, b.title))
    || []
  )

  private getGroupImages(group: Group | undefined, groups: Groups): string[] {
    if (group) {
      if (group.images.length) return group.images
      const parentGroup = this.getGroup(group.groups.slice(0, -1), groups)
      if (parentGroup) return this.getGroupImages(parentGroup, groups)
    }
    return []
  }

  private getGroup(pathSegments: string[], groups: Groups): Group | undefined {
    return Object.values(groups)
      .find(group =>
        group.groups.length === pathSegments.length
        && group.groups
          .every((group, i) =>
            idFromText(group) === idFromText(pathSegments[i])
          ))
  }

  private buildGroupVM(group: Group, groupImageObjects: GroupImage[]): GroupView {
    const parentGroups = group?.groups || []
    return {
      ...group,
      images: groupImageObjects,
      menuItem: {
        title: group.title,
        routerLink: "/" + [...parentGroups, group.title]
          .map(linkSegment => idFromText(linkSegment))
          .slice(0, -1)
          .join("/")
      },
      children: []
    }
  }

}
