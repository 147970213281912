import { ChangeDetectionStrategy, Component, inject } from "@angular/core"
import { ButtonComponent } from "../button/button.component"
import { AuthService } from "../../services/auth.service"
import { Store } from "@ngrx/store"
import * as AppStore from "../../store"
import { InheritDirective } from "../../directives/inherit.directive"
import { HlmButtonDirective } from "@spartan-ng/ui-button-helm"

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    ButtonComponent,
    InheritDirective,
    HlmButtonDirective,
  ],
  standalone: true,
  template: `
    <div style="text-align: center; color: white">
      <button
        hlmBtn
        variant="blue"
        size="medium"
        (click)="googleSignInWithPopup()"
      >
        Sign In with Google
      </button>
    </div>
  `,
  selector: "e2e-login-google-button",
})
export class LoginGoogleButtonComponent {
  private authService = inject(AuthService)
  // private analyticsService = inject(AnalyticsService)
  private store = inject(Store<AppStore.State>)

  googleSignInWithPopup(): void {
    this.store.dispatch(AppStore.UserActions.loadingStart())
    this.authService.googleSignInWithPopup()
      .then(() => {
        this.store.dispatch(AppStore.UserActions.loadingFinish())
      })
      .catch(error => {
        this.store.dispatch(AppStore.UserActions.loadingError({ error: error }))
        /*
                const event: ExceptionEvent = {
                  eventName: EventName.EXCEPTION,
                  eventParams: {
                    class: this.constructor.name,
                    function: "googleSignInWithPopup()",
                    action: "this.authService.googleSignInWithPopup()",
                    description: String(error),
                    stack: error.stack,
                    fatal: false
                  }
                }
                this.analyticsService.sendEvent(event)
        */
      })
  }

}
