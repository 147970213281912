import { Params, RouterStateSnapshot } from "@angular/router"
import { RouterStateSerializer } from "@ngrx/router-store"
import { idFromText } from "../../services/utils.service"

export interface RouterState {
  url: string
  params: Params
  queryParams: Params
  data: any
  fragment: string | null
  path: string
  pathSegments: string[]
  groupId: string
}

export class RouteSerializer implements RouterStateSerializer<RouterState> {
  serialize(routerState: RouterStateSnapshot): RouterState {
    let route = routerState.root
    while (route.firstChild) {
      route = route.firstChild
    }
    const { params, data, fragment } = route
    const { url, root: { queryParams } } = routerState

    const path = decodeURI(
      url
        .split("?")[0]
        .split("#")[0]
    )
    const pathSegments = path
      .split("/")
      .filter(segment => !!segment)
      .map(segment => idFromText(segment)) // lowercase and special characters replaced with underscores
    const groupId = idFromText(pathSegments.join("_")) // underscore joined, multiple underscores replaced with single underscores

    // instead of the entire snapshot
    // Only return an object including
    //   the URL, params and query params plus data and groupId
    return { params, data, fragment, url, queryParams, path, pathSegments, groupId }
  }
}
