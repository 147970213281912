import { GroupImage } from "../components/products/group.model"
import { environment } from "../../environments/environment"

export interface Options {
  source: "assets" | "bucket"
}

export const convertToImageObject = (image: string, options: Options = { source: "bucket" } ): GroupImage => {
  let imageUrl = ""
  switch (options.source) {
    case "assets":
      imageUrl = "/assets/" + image
      break
    case "bucket":
      imageUrl = "https://storage.googleapis.com/" + environment.firebaseConfig.storageBucket + "/images/" + image
      break
    default:
      imageUrl = "https://storage.googleapis.com/" + environment.firebaseConfig.storageBucket + "/images/" + image
  }
  return {
    itemImageSrc: imageUrl,
    thumbnailImageSrc: imageUrl,
    alt: "image",
    title: "image"
  }
}
