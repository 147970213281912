import { ChangeDetectionStrategy, Component, inject } from "@angular/core"
import { LoginLoggedInComponent } from "./login-logged-in.component"
import { LoginLoggedOutComponent } from "./login-logged-out.component"
import { Store } from "@ngrx/store"
import * as AppStore from "../../store"
import { InheritDirective } from "../../directives/inherit.directive"

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    LoginLoggedInComponent,
    LoginLoggedOutComponent,
    InheritDirective,
],
  providers: [],
  standalone: true,
  template: `
    <div class="row" style="justify-content: center; color: #0a0a0a; padding: 20px">
      <div class="column" style="width: 350px; align-content: stretch;">

        @if (loggedIn()) {
          <div style="font-size: 36px; text-align: center">
            Welcome Back
          </div>
          <e2e-login-logged-in/>
        }

        @if (loggedOut()) {
          <div style="font-size: 36px; text-align: center">
            Welcome Back
          </div>
          <e2e-login-logged-out/>
        }

      </div>
    </div>
  `,
  selector: "e2e-login",
})
export class LoginComponent {
  private store = inject(Store<AppStore.State>)

  loggedIn = this.store.selectSignal(AppStore.loggedIn)
  loggedOut = this.store.selectSignal(AppStore.loggedOut)
  loading = this.store.selectSignal(AppStore.loading)


}
