import { ChangeDetectionStrategy, Component, computed, inject, signal } from "@angular/core"
import { InheritDirective } from "../../directives/inherit.directive"
import { Groups } from "../../store/content/content.model"
import { idFromText } from "../../services/utils.service"
import { RouteService } from "../../services/route.service"
import { FirestoreReadService } from "../../services/firestore-read.service"
import { FaIconComponent } from "@fortawesome/angular-fontawesome"
import { faAngleRight, faHouseChimney } from "@fortawesome/pro-solid-svg-icons"
import { RouterLink } from "@angular/router"

type Breadcrumb = {
  label: string;
  routerLink: string
}

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    InheritDirective,
    FaIconComponent,
    RouterLink,
  ],
  standalone: true,
  template: `
    <div style="padding: 6px 16px; border: 1px #e8e8e8 solid; border-radius: 6px">
      <nav
        role="tablist"
        aria-label="Breadcrumb"
        class="row"
      >
        <a
          routerLink="/"
          rel="noopener noreferrer"
          role="listitem"
        >
          <fa-icon [icon]="faHouseChimney"/>
        </a>
        <fa-icon role="presentation" [icon]="faAngleRight" style="font-size: 24px"/>
        @for (breadcrumb of breadcrumbs(); track breadcrumb.label; let last = $last) {
          @if (!last) {
            <a
              [routerLink]="breadcrumb.routerLink"
              rel="noopener noreferrer"
              role="listitem"
            >
              {{ breadcrumb.label }}
            </a>
            <fa-icon role="presentation" [icon]="faAngleRight" style="font-size: 24px"/>
          }
          @if (last) {
            {{ breadcrumb.label }}
          }
        }
      </nav>
    </div>
  `,
  selector: "e2e-breadcrumb",
})
export class BreadcrumbComponent {
  private routeService = inject(RouteService)
  private firestoreReadService = inject(FirestoreReadService)

  home = signal({ icon: "pi pi-home", routerLink: "/" })

  breadcrumbs = computed<Breadcrumb[]>(() => {
    const breadcrumbSegments: string[] = []
    return this.routeService.pathSegments().map(pathSegment => {
      const getGroupLabel = (breadcrumbSegments: string[], groups: Groups): string | undefined => {
        const group = Object.values(groups).find(group =>
          idFromText(group.groups.join("_")) === breadcrumbSegments.join("_"))
        return group?.groups.slice(-1)[0]
      }
      breadcrumbSegments.push(pathSegment)
      return {
        label: getGroupLabel(breadcrumbSegments, this.firestoreReadService.groups_keyed()) || pathSegment,
        routerLink: "/" + breadcrumbSegments.join("/")
      }
    }) || []
  })

  protected readonly faAngleRight = faAngleRight
  protected readonly faHouseChimney = faHouseChimney
}
