import { Component, inject } from "@angular/core"
import { Store } from "@ngrx/store"
import * as AppStore from "../store"
import { read, utils } from "xlsx"
import { ImportDataRaw } from "../store/import/import.model"
import { Storage, ref, uploadBytesResumable } from "@angular/fire/storage"
import { InheritDirective } from "../directives/inherit.directive"
// import { ref, Storage as AngularFireStorage, uploadBytesResumable } from "@angular/fire/storage"

@Component({
  imports: [
    InheritDirective,
  ],
  providers: [],
  standalone: true,
  template: `
    <input
      id="file-input"
      type="file"
      accept=""
      (change)="fileChangeEvent($event)"
    >
  `,
  selector: "e2e-import",
})
export class ImportComponent {
  private store = inject(Store<AppStore.State>)
  private storage = inject(Storage)

  fileChangeEvent(event: Event) {
    /**
     * setup reader and onload listener
     * then
     * - upload files[0] to bucket storage
     * - trigger reader.onload with files[0]
     */
    const fileReader = new FileReader()
    fileReader.onload = (() => {

      const arrayBuffer = fileReader.result as ArrayBuffer
      if (arrayBuffer) { // TODO: check is not string | null, must be type ArrayBuffer
        const data = new Uint8Array(arrayBuffer)
        const arr: string[] = []
        data.map((charCode: number) => {
          arr.push(String.fromCharCode(charCode))
          return 0
        })
        // for (let i = 0; i != data.length; ++i) {
        //   arr[i] = String.fromCharCode(data[i])
        // }
        const binaryString = arr.join("")
        const workbook = read(binaryString, { type: "binary" })
        const sheetNames = workbook.SheetNames
        const worksheet = workbook.Sheets[sheetNames[0]]
        const excelJsonData: ImportDataRaw[] = utils.sheet_to_json(worksheet, {
          blankrows: false,
          raw: true,
          defval: "",
          skipHidden: true,
          rawNumbers: true,
        })

        /**
         * TODO: test data structure, maybe with zod???
         */

        // console.log(excelJsonData)
        this.store.dispatch(AppStore.ImportActions.processImportedData({ data: excelJsonData }))
      }
    })

    const target = event.target as HTMLInputElement
    const files = target.files as FileList
    /**
     * Save original data to storage bucket
     */
    // this.uploadToBucket(files[0], "imports/" + files[0].name)
    /**
     * trigger fileReader.onload above
     */
    // console.log(files)
    fileReader.readAsArrayBuffer(files[0])
  }

  /**
   * move to service or ngrx effects
   */
  uploadToBucket(file: File, storageUrl: string) {
    const storageRef = ref(this.storage, storageUrl)
    uploadBytesResumable(storageRef, file)
      .then(snap => {
        if (snap) {
          if (snap.state === "success") {
            console.log("uploaded")
          }
        }
      })
  }

  /**
   * I believe everything below can be deleted
   */


/*
  private afs = inject(AngularFirestore)
  private utils = inject(UtilsService)
  private dataSaveService = inject(DataSaveService)
  private contentService = inject(ContentService)
  private afStorage = inject(AngularFireStorage)

  groupDocs$ = this.store.select(AppStore.groupDocs)
    .pipe(tap(groupDocs => this.groupDocs = groupDocs))
  groupDocs: ContentDocs = {}
  itemDocs$ = this.store.select(AppStore.itemDocs)
    .pipe(tap(itemDocs => this.itemDocs = itemDocs))
  itemDocs: ContentDocs = {}
  groupsAndItems$ = this.store.select(AppStore.groupsAndItems)
    .pipe(tap(content => this.groupsAndItems = content))
  groupsAndItems: ContentDoc["content"] = {}
  rootGroups$ = this.store.select(AppStore.rootGroups)
    .pipe(tap(content => this.rootGroups = content))
  rootGroups: ContentDoc["content"] = {}
  dataFromRows: DataFromRow[] = []
  purge$ = this.store.select(AppStore.purge)
    .pipe(tap(purge => {
      if (purge) {
        // console.log(this.groupDocs)
        // console.log(this.itemDocs)
        this.dataSaveService.purgeDocs(this.groupDocs, ContentType.GROUP)
        this.dataSaveService.purgeDocs(this.itemDocs, ContentType.ITEM)
        // .then(() => console.log("purged groups"))
        // .catch((error) => console.log(error))
      }
    }))
  importContent$ = this.store.select(AppStore.importContent)
    .pipe(tap(importContent => {
      // console.log(importContent)
      if (importContent) this.findGroups()
    }))

  constructor(
    @Inject(LOCAL_STORAGE)
    private localStorage: Storage,
  ) {
    rxEffects(({ register }) => register(this.groupDocs$))
    rxEffects(({ register }) => register(this.groupsAndItems$))
    rxEffects(({ register }) => register(this.itemDocs$))
    rxEffects(({ register }) => register(this.purge$))
    rxEffects(({ register }) => register(this.importContent$))

    const dataFromRowsString = this.localStorage.getItem("dataFromRows")
    if (dataFromRowsString) {
      const dataFromRows = JSON.parse(dataFromRowsString)
      if (dataFromRows) this.dataFromRows = dataFromRows.slice(0, 10)
    }
  }

  csvData(data: string) {
    const previousRow: string[] = []
    const processedData = data
      .replace(/\\r\\r/g, "") // line returns
      .replace("\r\n", "\r") // line returns
      .split("\r") // line returns
      .map(row => { // split row into cells
        const cells = row
          .replace(/(?!\B"[^"]*),(?![^"]*"\B)/g, "|") // handle " within the cells by converting delimiter to |
          .split("|")

        // const currencyRegex = /[$+-]?[0-9]{1,3}(?:,?[0-9]{3})*\.[0-9]{2}/
        const currencyRegex = /\$?-?([1-9][0-9]{0,2}(,\d{3})*(\.\d{0,2})?|[1-9]\d*(\.\d{0,2})?|0(\.\d{0,2})?|(\.\d{1,2}))$|^-?\$?([1-9]\d{0,2}(,\d{3})*(\.\d{0,2})?|[1-9]\d*(\.\d{0,2})?|0(\.\d{0,2})?|(\.\d{1,2}))$|^\(\$?([1-9]\d{0,2}(,\d{3})*(\.\d{0,2})?|[1-9]\d*(\.\d{0,2})?|0(\.\d{0,2})?|(\.\d{1,2}))\)/
        return (currencyRegex.test(cells[CSVData.RETAIL_PRICE])) ? cells : [] // empty [] if Retail column is not valid currency value
      })
      .filter(row => !!row.length) // strip empty rows, keeping only rows with valid currency value
      .map(cells => { // copy cell from previous row if cell is empty
        for (const index in cells) {
          cells[index] = cells[index] || previousRow[index] || "" // copy from previous and guarantee not undefined
          previousRow[index] = cells[index]
        }
        return cells
          .map(cell => cell
            .replace(/^\s+/, "") // trim leading whitespace
            .replace(/\s+$/, "") // trim trailing whitespace
            .replace(/^"/, "") // trim leading "
            .replace(/"$/, "") // trim trailing "
            .replace(/"+/g, "\"") // replace multiple "s with single "
          )
      })
    this.convertRowsToData(processedData)
  }

  convertRowsToData(rows: string[][]) {
    const dataFromRows: DataFromRow[] = rows.map(row => {
      const dataFromRow: DataFromRow = {
        groups: row[CSVData.GROUPS]
          // .replace(/\s\/\s/g, "*") // " / "
          // .replace(/\/\s/g, "*") //   "/ "
          // .replace(/\s\//g, "*") //   " /"
          .split("*")
          .map(group => group
            .replace(/^\s+/, "") // trim leading whitespace
            .replace(/\s+$/, "") // trim trailing whitespace
          ),
        title: row[CSVData.TITLE],
        manufacturer: row[CSVData.MANUFACTURER],
        retailPrice: row[CSVData.RETAIL_PRICE],
        sellingUnit: row[CSVData.SELLING_UNIT],
        sku: idFromText(row[CSVData.SKU]),
        description: row[CSVData.DESCRIPTION],
        images: row[CSVData.IMAGES]
          .split(",")
          .map(image => image
            .replace(/^\s+/, "") // trim leading whitespace
            .replace(/\s+$/, "") // trim trailing whitespace
          ),
      }
      if (!dataFromRow.sku) dataFromRow.sku = idFromText(row[CSVData.TITLE])
      return dataFromRow
    })

    this.dataFromRows = dataFromRows.slice(0, 10000)
    this.localStorage.setItem("dataFromRows", JSON.stringify(dataFromRows))

    /!**
     * convert data to Content
     * group Content and item Content
     *!/
    this.findGroups()
  }

  /!**
   * dataFromRows
   *
   * check groupsContent if root group exists
   * - if yes, check if subgroup exists [and return to previous step]
   * - if not, create group
   *   - if new group has subgroup, add child group and add reference to parent group [and repeat this step]
   *
   * add item and add item reference to parent group (last group in groups)
   *!/

  findGroups() {
    // console.timeEnd("build data from rows")
    // console.time("build groups and items")

    // console.log(this.groupDocs)
    // console.log(cleanCopy(this.groupDocs))
    let groupDocs: ContentDocs = cleanCopy(this.groupDocs)
    let itemDocs: ContentDocs = cleanCopy(this.itemDocs)
    // let contentTitleRefMap: RefMap = {}
    // ([ ...groupDocs, ...itemDocs].map(groupDoc => {
    //   Object.entries(groupDoc.content).map(contentEntry => {
    //     const [contentId, content] = contentEntry
    //     const titleRef = idFromText(this.getTitleFromContent(content))
    //     contentTitleRefMap[titleRef] = contentId
    //   })
    // }))
    // console.time("initial build groups and items")
    let groups = Object.entries(groupDocs)
      .reduce((accumulator: ContentDoc["content"], currentValue) => {
        return { ...accumulator, ...currentValue[1].content }
      }, {})
    let items = Object.entries(itemDocs)
      .reduce((accumulator: ContentDoc["content"], currentValue) => {
        return { ...accumulator, ...currentValue[1].content }
      }, {})
    // console.timeEnd("initial build groups and items")
    //
    // console.time("dataFromRows.map")
    this.dataFromRows.map(dataFromRow => {
      const allContent = { ...groups, ...items }


      let b = writeBatch(this.afs)
      let previousGroup: Content | null = null // last group is itemGroup

      const groupTitles: string[] = []
      dataFromRow.groups.map(groupTitle => {
        groupTitles.push(groupTitle)
        const groupTitlesAsContentId = idFromText(groupTitles.join("_")) // roughly matches breadcrumb for each group
        const content = allContent[groupTitlesAsContentId]
        const groupDataFromRow = JSON.parse(JSON.stringify(dataFromRow))
        groupDataFromRow.sku = groupTitlesAsContentId
        groupDataFromRow.title = groupTitle

        if (!content) { // is new(not found in allContent): create new group
          switch (!previousGroup) {
            case true: // is root group(previousGroup not found): create new root group
              ({ b, previousGroup, groupDocs, groups, items } = this.addGroup(
                b,
                groupTitlesAsContentId,
                ContentType.ROOT_GROUP,
                groupDocs,
                groups,
                items,
                groupDataFromRow,
              ))
              break
            case false: // is not root group(previousGroup is found): process child group(s)
              ({ b, previousGroup, groupDocs, groups, items } = this.addChildGroup(
                b,
                groupTitlesAsContentId,
                previousGroup as Content,
                groupDocs,
                groups,
                items,
                groupDataFromRow,
              ))
              break
          }
        }
        if (content) previousGroup = content // do nothing and move on to next group
      })

      // console.log(allContent)
      // console.log(dataFromRow.sku)

      /!**
       * add item to previousGroup, last group in groups is itemGroup
       *!/
      if (!allContent[dataFromRow.sku] && previousGroup) {
        ({ b, groupDocs, itemDocs, groups, items } = this.addItem(
          b,
          dataFromRow,
          previousGroup,
          groupDocs,
          itemDocs,
          groups,
          items,
        ))
      }
    })
    // console.timeEnd("dataFromRows.map")

    const batch = writeBatch(this.afs)
    Object.entries(groupDocs)
      .map(contentDocEntry => batch.set(
        doc(collection(this.afs, "content"), contentDocEntry[0]),
        cleanCopy(contentDocEntry[1]),
        { merge: true }
      ))
    Object.entries(itemDocs)
      .map(contentDocEntry => batch.set(
        doc(collection(this.afs, "content"), contentDocEntry[0]),
        cleanCopy(contentDocEntry[1]),
        { merge: true }
      ))

    /!*
        Object.entries(groupDocs)
          .map(contentDocEntry => {
            console.log(stringify(contentDocEntry[1]))
            console.log(Object.keys(contentDocEntry[1].content).length)
            console.log(stringify(contentDocEntry[1]).length)
          })
        Object.entries(itemDocs)
          .map(contentDocEntry => {
            console.log(stringify(contentDocEntry[1]))
            console.log(Object.keys(contentDocEntry[1].content).length)
            console.log(stringify(contentDocEntry[1]).length)
          })

        console.timeEnd("build groups and items")
        console.time("save to firebase")
        console.log("begin save")
    *!/

    batch.commit()
      .then(() => {
        console.log("end save")
        // console.timeEnd("save to firebase")
      })
      .catch((error) => {
        console.log("failed save")
        // console.timeEnd("save to firebase")
        console.error(error)
      })
  }

  addGroup(
    b: WriteBatch,
    contentId: string,
    contentType: ContentType,
    contentDocs: ContentDocs,
    groups: ContentDoc["content"],
    items: ContentDoc["content"],
    dataFromRow: DataFromRow
  ) {
    // add rootGroup content
    const content = this.dataFromRowToContent(dataFromRow, contentType, contentId)

    const contentResponse = this.dataSaveService
      .saveContentToGroups(
        content.type,
        content,
        content.id,
        contentDocs,
        b
      )
    groups[content.id] = content
    return {
      b: contentResponse.b,
      previousGroup: content,
      groupDocs: contentResponse.contentDocs,
      groups,
      items,
    }
  }

  addChildGroup(
    b: WriteBatch,
    childContentId: string,
    parentContent: Content,
    contentDocs: ContentDocs,
    groups: ContentDoc["content"],
    items: ContentDoc["content"],
    dataFromRow: DataFromRow
  ) {
    const previousGroupResponse = this
      .addReferenceToParentGroup(
        b,
        childContentId,
        parentContent,
        contentDocs,
        groups,
      )
    const addGroupResponse = this
      .addGroup(
        previousGroupResponse.b,
        childContentId,
        ContentType.GROUP,
        previousGroupResponse.contentDocs,
        previousGroupResponse.groups,
        items,
        dataFromRow,
      )
    return {
      b: addGroupResponse.b,
      previousGroup: addGroupResponse.previousGroup,
      groupDocs: addGroupResponse.groupDocs,
      groups: addGroupResponse.groups,
      items,
    }
  }

  addItem(
    b: WriteBatch,
    dataFromRow: DataFromRow,
    parentContent: Content,
    groupDocs: ContentDocs,
    itemDocs: ContentDocs,
    groups: ContentDoc["content"],
    items: ContentDoc["content"]
  ) {
    const previousGroupResponse = this
      .addReferenceToParentGroup(
        b,
        dataFromRow.sku,
        parentContent,
        groupDocs,
        groups,
      )
    groups[parentContent.id] = previousGroupResponse.groups[parentContent.id]
    // add item content
    const content = this.dataFromRowToContent(dataFromRow, ContentType.ITEM, dataFromRow.sku)
    items[content.id] = content // using dataFromRow.sku
    const contentResponse = this.dataSaveService
      .saveContentToGroups(
        content.type,
        content,
        content.id, // dataFromRow.sku
        itemDocs,
        previousGroupResponse.b,
      )
    return {
      b: contentResponse.b,
      groupDocs: previousGroupResponse.contentDocs,
      itemDocs: contentResponse.contentDocs,
      groups,
      items,
    }
  }

  addReferenceToParentGroup(
    b: WriteBatch,
    childContentId: string,
    parentContent: Content,
    contentDocs: ContentDocs,
    groups: ContentDoc["content"],
  ) {
    const cleanParentContent = cleanCopy(parentContent)
    const contentReferenceField: ContentReference = {
      contentId: childContentId,
      type: FieldType.CONTENT_REFERENCE,
    }
    cleanParentContent.fieldGroups[0].fields.push(contentReferenceField)

    groups[cleanParentContent.id] = cleanParentContent

    const dataSaveResponse = this.dataSaveService
      .saveContentToGroups(
        cleanParentContent.type,
        cleanParentContent,
        cleanParentContent.id,
        contentDocs,
        b,
      )
    return {
      b: dataSaveResponse.b,
      contentDocs: dataSaveResponse.contentDocs,
      groups
    }
  }

  /!**
   * look at parent rows for contentReference that has a matching title.
   * this way we could have content elsewhere with the same titles.
   *!/

  /!*
    findContentByTitle(contentTitle: string, previousContent: Content | null, isRootGroup: boolean, rootGroups: ContentDoc["content"], groups: ContentDoc["content"], contentTitleRefMap: RefMap) {
      /!**
       * previousGroups.length and isRootGroup are mutually exclusive, both will never be truthy or falsey at the same time
       *!/
      if (isRootGroup) {
        return rootGroups[contentTitleRefMap[contentTitle]]
        return rootGroups
          .filter(rootGroup => this.contentHasTitle(rootGroup, contentTitle))
      }
      if (previousContent) {
        return previousContent.columns[0].rows
          .map(rowId => {
            const contentReferenceField = previousContent.rows[rowId] as ContentReference
            const contentId = contentReferenceField.contentId
            const content = groups.filter(content => content.id === contentId)[0]
            if (content && this.contentHasTitle(content, contentTitle))
              return content
            return null
          })
          .filter(content => !!content) as Content[]
      }
      return []
    }
  *!/

  /!*
    contentHasTitle(content: Content, title: string) {
      return !!content.columns[0].rows.find(rowId => {
        const row = content.rows[rowId] as TextArea
        return row.type === FieldType.TEXT_AREA && row.section === SectionType.TITLE && row.value === title
      })
    }
  *!/

  dataFromRowToContent(
    dataFromRow: DataFromRow,
    contentType: ContentType,
    contentId: string
  ) {
    const content: Content = this.contentService.defaultContent(contentType, dataFromRow.sku)

    const titleField: TextArea = {
      section: SectionType.TITLE,
      type: FieldType.TEXT_AREA,
      value: dataFromRow.title
    }
    const descriptionField: TextArea = {
      section: SectionType.DESCRIPTION,
      type: FieldType.TEXT_AREA,
      value: dataFromRow.title
    }
    const priceField: TextArea = {
      section: SectionType.PRICE,
      type: FieldType.TEXT_AREA,
      value: dataFromRow.retailPrice
    }
    const sellingUnitField: TextArea = {
      section: SectionType.SELLING_UNIT,
      type: FieldType.TEXT_AREA,
      value: dataFromRow.sellingUnit
    }
    const manufacturerField: TextArea = {
      section: SectionType.MANUFACTURER,
      type: FieldType.TEXT_AREA,
      value: dataFromRow.manufacturer
    }

    switch (contentType) {
      case ContentType.GROUP:
      case ContentType.ROOT_GROUP:
        content.fieldGroups[0].fields.push(titleField)
        break
      case ContentType.MENU:
      case ContentType.PAGE:
      case ContentType.PRODUCT:
        break
      case ContentType.ITEM: {
        content.fieldGroups[0].fields.push(titleField)
        content.fieldGroups[0].fields.push(descriptionField)
        content.fieldGroups[0].fields.push(priceField)
        content.fieldGroups[0].fields.push(sellingUnitField)
        content.fieldGroups[0].fields.push(manufacturerField)
      }
        break
    }

    return content
  }

  getTitleFromContent(content: Content) {
    let title = ""
    content.fieldGroups
      .map(fieldGroup => fieldGroup.fields.map(field => {
        const textAreaField = field as TextArea
        if (textAreaField.type === FieldType.TEXT_AREA && textAreaField.section === SectionType.TITLE) {
          title = textAreaField.value
        }
      }))
    return title
  }
*/

}
