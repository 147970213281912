import { ChangeDetectionStrategy, Component, computed, inject } from "@angular/core"
import { CommonModule } from "@angular/common"
import { MenubarModule } from "primeng/menubar"
import { InputTextModule } from "primeng/inputtext"
import { UserAvatarComponent } from "./user-avatar.component"
import { Store } from "@ngrx/store"
import * as AppStore from "../store"
import { LetDirective } from "@ngrx/component"
import { ButtonComponent } from "./button/button.component"
import { ImportComponent } from "./import.component"
import { InheritDirective } from "../directives/inherit.directive"

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    MenubarModule,
    InputTextModule,
    UserAvatarComponent,
    LetDirective,
    ButtonComponent,
    ImportComponent,
    InheritDirective,
  ],
  providers: [],
  standalone: true,
  template: `
    <p-menubar [model]="menuItems()">
      <ng-template pTemplate="start">
        <div class="row">
          <a
            style="width: 40px"
            routerLink="/"
          >
            <img
              src="../../assets/rtduggan-omega-logo.png"
              height="152"
              width="85"
              style="width: 20px; 
              height: 40px"
              alt=""
            />
          </a>
          <e2e-user-avatar/>
        </div>
      </ng-template>
      
      
      
      <ng-template pTemplate="end">
        <div class="row" style="color: white">
          @if (loggedIn()) {
            <e2e-import></e2e-import>
            <!--
            <e2e-button
              *ngrxLet="purgeContent$ as purgeContent"
              size="medium"
              [color]="purgeContent ? 'white' : 'blue'"
              [label]="purgeContent ? 'purged' : 'purge'"
              (click)="purge()"
            ></e2e-button>
            -->
              <!--
              <e2e-button
                *ngrxLet="importContent$ as importContent"
                size="medium"
                [color]="importContent ? 'white' : 'blue'"
                [label]="importContent ? 'imported' : 'import'"
                (click)="import()"
              ></e2e-button>
              -->
          }
        </div>
      </ng-template>
    </p-menubar>
  `,
  selector: "e2e-toolbar-menu",
})
export class ToolbarMenuComponent {
  private store = inject(Store<AppStore.State>)

  loggedIn = this.store.selectSignal(AppStore.loggedIn)
  menuItems = computed(() => ([
    {
      label: "Create Account",
      routerLink: "/login",
      visible: !this.loggedIn(),
    },
    {
      label: "Sign In",
      routerLink: "/login",
      visible: !this.loggedIn(),
    },
    {
      label: "Sign Out",
      routerLink: "/login",
      visible: !!this.loggedIn(),
    },
  ]))

  purge() {
    this.store.dispatch(AppStore.ContentActions.contentPurge())
  }

  import() {
    this.store.dispatch(AppStore.ContentActions.contentImport())
  }
}
