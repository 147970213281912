import { Route } from "@angular/router"
import { LoginComponent } from "./components/login/login.component"
import { ProductsPageComponent } from "./components/products/products-page.component"
import { DocumentationComponent } from "./components/documentation.component"

export const appRoutes: Route[] = [
  { path: "", pathMatch: "full", component: ProductsPageComponent },
  { path: "login", component: LoginComponent },
  { path: "documentation", component: DocumentationComponent },
  // { path: "multiblock", component: AnyBlockComponent },
  { path: "**", component: ProductsPageComponent }
]
