import { effect, inject, Injectable, signal, untracked } from "@angular/core"
import { ActivatedRoute, NavigationEnd, Params, Router, RouterEvent } from "@angular/router"

/**
 * these values are used by the router
 */
interface QueryParams extends Params {
  debug?: string
}

@Injectable({
  providedIn: "root"
})
export class RouteService {
  private router = inject(Router)
  private activatedRoute = inject(ActivatedRoute)

  private _debug = signal(false)
  readonly debug = this._debug.asReadonly()

  private _fragments = signal<string[]>([])
  readonly fragments = this._fragments.asReadonly()

  private _pathSegments = signal<string[]>([])
  readonly pathSegments = this._pathSegments.asReadonly()

  private _url = signal("")
  readonly url = this._url.asReadonly()

  private _routerEvent = signal<RouterEvent | undefined>(undefined)
  private _activatedRoute_fragment = signal<string | null | undefined>(undefined)

  constructor() {
    this.subscribeToRouterEvents()
    this.subscribeToActivatedRoute_fragment()
    this.subscribeToActivatedRoute_queryParams()

    effect(() => {
      const routerEvent = this._routerEvent()
      const activatedRouteFragment = this._activatedRoute_fragment()
      untracked(() => {
        this.onRouteChange(routerEvent, activatedRouteFragment)
      })
    })
  }

  subscribeToActivatedRoute_queryParams() {
    return this.activatedRoute.queryParams
      .subscribe((queryParams: QueryParams) => {
        const debugFromStorage = localStorage.getItem("debug")
        const debug = queryParams.debug !== undefined
          ? queryParams.debug
          : debugFromStorage !== null
            ? debugFromStorage
            : undefined
        if (debug !== undefined) {
          this._debug.set(debug === "true" || debug === "")
          localStorage.setItem("debug", debug)
        }
      })
  }

  changeFragment(fragment: string) {
    this.changeRoute([], fragment)
  }

  removeFragment() {
    this.changeFragment("")
  }

  changeRoute(route: string[], fragment?: string) {
    if (fragment) {
      this.router
        .navigate(route, { queryParamsHandling: "preserve", fragment })
        .then()
    }
    if (!fragment) {
      this.router
        .navigate(route, { queryParamsHandling: "preserve" })
        .then()
    }
  }

  onRouteChange(routerEvent: RouterEvent | undefined, activatedRouteFragment: string | null | undefined) {
    if (routerEvent) {

      let root = this.router.routerState.snapshot.root
      while (root) {
        if (root.children && root.children.length) {
          root = root.children[0]
        } else {
          // this._routeHasPageToolbar.set(root.data && root.data["routeHasPageToolbar"])
          break
        }
      }

      if (location.pathname === "/local-maps" && !location.hash) {
        // this.changeFragment(this.localMapsMode().toLowerCase())
      }

      this._url.set(routerEvent.url)

      const hostnameArray = location.hostname.split(".")

    }

    const pathSegments = location.pathname
      .replace(/^\//, "")
      .toLowerCase()
      .split("/")
      .filter(segment => !!segment)
    this._pathSegments.set(pathSegments)

    const fragments = activatedRouteFragment ? activatedRouteFragment.split("/") : [""]
    // while (fragments.length < 4) {
    //   fragments.push("")
    // }
    this._fragments.set(fragments)


  }

  subscribeToRouterEvents() {
    return this.router.events
      .subscribe(event_2 => {
        if (event_2 instanceof NavigationEnd) {
          this._routerEvent.set(event_2)
        }
      })
  }

  subscribeToActivatedRoute_fragment() {
    return this.activatedRoute.fragment
      .subscribe(fragment => {
        this._activatedRoute_fragment.set(fragment)
      })
  }

}
