import { Environment } from "./environment.model"

/**
 * copy/pasted from firebase project settings
 */
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBzf4m7zYVvn035gnTEW8jQgGJZEO7XS7w",
  authDomain: "rtduggan-1.firebaseapp.com",
  databaseURL: "https://rtduggan-1.firebaseio.com",
  projectId: "rtduggan-1",
  storageBucket: "rtduggan-1.appspot.com",
  messagingSenderId: "804626435719",
  appId: "1:804626435719:web:45c8a046f94977f365f222",
  measurementId: "G-QG5M67YT4J"
}

export const environment: Environment = {
  production: true,
  baseUrl: "https://www.rtduggan.com",
  firebaseConfig,
  stripeKey: "pk_live_Ud947vwLiiJuJKjnJghagn3v",
}

// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
