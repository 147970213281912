import { Component, computed, effect, inject } from "@angular/core"
import { PanelMenuModule } from "primeng/panelmenu"
import { Store } from "@ngrx/store"
import * as AppStore from "../../store"
import { LetDirective } from "@ngrx/component"
import { cloneDeep } from "lodash-es"
import { HlmButtonDirective } from "@spartan-ng/ui-button-helm"
import { InheritDirective } from "../../directives/inherit.directive"
import { GroupService } from "../products/group.service"
import { FirestoreReadService } from "../../services/firestore-read.service"

@Component({
  imports: [
    PanelMenuModule,
    LetDirective,
    HlmButtonDirective,
    InheritDirective,
],
  providers: [],
  standalone: true,
  template: `
    <div class="flex flex-col gap-1"
         style="padding-right: 15px; padding-bottom: 50px; overflow: scroll; max-height: 90vh; min-width: 250px">
      @for (menuItem of menuItems(); track menuItem.label) {
        <a
          hlmBtn
          variant="rtdugganOutlineMenu"
          size="lg"
          [routerLink]="menuItem.routerLink"
        >
          {{ menuItem.label }}
        </a>
      }
    </div>

  `,
  selector: "e2e-sidebar",
})
export class SidebarComponent {
  private firestoreReadService = inject(FirestoreReadService)

  menuItems = this.firestoreReadService.menuItems_array

}
