import { props, emptyProps, createActionGroup } from "@ngrx/store"
import { User } from "@angular/fire/auth"

export const UserActions = createActionGroup({
  source: "User",
  events: {
    // Address
    "Add": emptyProps(),
    "Remove": emptyProps(),
    "Update": emptyProps(),
    "Google Sign In With Popup": emptyProps(),
    "User Login": props<{ username: string, password: string }>(),
    "Login Success": props<{ token: string }>(),
    "Login Failure": props<{ error: string }>(),
    "Get User": emptyProps(),

    "Loading Start": emptyProps(),
    "Loading Finish": emptyProps(),
    "Loading Error": props<{ error: string }>(),

    "auth state change": props<{ afUser: Partial<User> | null }>(),
    "authenticated": props<{ afUser: Partial<User> }>(),
    // "not authenticated": emptyProps(),
  },
})
