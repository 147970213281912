import { inject, Injectable } from "@angular/core"
import { Router } from "@angular/router"
import {
  Auth,
  signInWithPopup as afSignInWithPopup,
  createUserWithEmailAndPassword as afCreateUserWithEmailAndPassword,
  signInWithEmailAndPassword as afSignInWithEmailAndPassword,
  GoogleAuthProvider,
  GithubAuthProvider,
  FacebookAuthProvider,
  TwitterAuthProvider,
  sendEmailVerification,
} from "@angular/fire/auth"
// import { AnalyticsService, EventName, ExceptionEvent } from "./analytics.service"
// import { throwError } from "rxjs"
import { UtilsService } from "./utils.service"
import { Store } from "@ngrx/store"
import * as AppStore from "../store"

export type UserRoles = {
  [key in Role]: boolean
}

export enum Role {
  ADMIN = "ADMIN",
  EDITOR = "EDITOR",
  USER_ADMIN = "USER_ADMIN",
}

@Injectable({
  providedIn: "root",
})
export class AuthService {
  private auth = inject(Auth)
  private router = inject(Router)
  // private analyticsService = inject(AnalyticsService)
  private utils = inject(UtilsService)
  private store = inject(Store<AppStore.State>)

  constructor(
  ) {

    // this.rxEffects.register(this.afAuthUser$)
    // this.rxEffects.register(this.nullUser$)

/*
    this.afAuth.onAuthStateChanged(user => {
      this.globalState.set("afAuthUser", () => user)
      this.globalState.set("afAuthUserId", () => user?.uid)
      this.globalState.set("loggedIn", () => !!user)
      if (!user?.uid) {
        this.globalState.set("hasRole", () => {
          return {}
        })
        this.dataService.allContributedContent$.next([])
        this.dataService.uidContributedContent$.next([])

      }

      if (user?.uid) {
        user.getIdTokenResult()
          .then(idTokenResult => {
            console.info("signInProvider", idTokenResult.signInProvider)
            console.info("claims", idTokenResult.claims)
            // console.info("idToken", idTokenResult.token)
            // console.info("authTime", idTokenResult.authTime)
            // console.info("expirationTime", idTokenResult.expirationTime)
            // console.info("issuedAtTime", idTokenResult.issuedAtTime)
            // console.info("signInSecondFactor", idTokenResult.signInSecondFactor)
          })
          .catch(error => {
            const event: ExceptionEvent = {
              eventName: EventName.EXCEPTION,
              eventParams: {
                class: this.constructor.name,
                function: "constructor()",
                action: "user.getIdTokenResult()",
                description: String(error),
                stack: error.stack,
                fatal: false
              }
            }
            this.analyticsService.sendEvent(event)
          })

        user.getIdToken()
          .then()
          .catch(error => {
            const event: ExceptionEvent = {
              eventName: EventName.EXCEPTION,
              eventParams: {
                class: this.constructor.name,
                function: "constructor()",
                action: "user.getIdToken()",
                description: String(error),
                stack: error.stack,
                fatal: false
              }
            }
            this.analyticsService.sendEvent(event)
          })
      }
    })
*/

/*
    this.afAuth.onIdTokenChanged(idToken => {
      if (idToken === null) {
        this.globalState.set("userAccount", () => {
          return {}
        })
        this.globalState.set("userProfile", () => {
          return {} as Profile
        })
      }
    })
*/
  }

  signOut(): void {
    if (this.router.url === "/users") {
      this.router.navigate(["/"])
        .then()
        .catch(error => {
/*
          const event: ExceptionEvent = {
            eventName: EventName.EXCEPTION,
            eventParams: {
              class: this.constructor.name,
              function: "signOut()",
              action: "this.router.navigate([\"/\"])",
              description: String(error),
              fatal: false
            }
          }
          this.analyticsService.sendEvent(event)
*/
        })
    }
    this.auth.signOut()
      .then(() => {
        // this.store.dispatch
      })
      .catch(error => {
        console.log(error)
/*
        const event: ExceptionEvent = {
          eventName: EventName.EXCEPTION,
          eventParams: {
            class: this.constructor.name,
            function: "signOut()",
            action: "this.afAuth.signOut()",
            description: String(error),
            stack: error.stack,
            fatal: false
          }
        }
        this.analyticsService.sendEvent(event)
*/
      })
  }

  createUserWithEmailAndPassword(email: string, password: string) {
    return afCreateUserWithEmailAndPassword(this.auth, email, password)
      .then(userCredential => {

        if (userCredential?.user) {
          sendEmailVerification(userCredential.user).then().catch(error => console.log(error))
          // this.updateUserAccount(userCredential.user)
        }
      })
/*
      .catch((error) => {
        // Handle Errors here.
        const errorCode = error.code
        const errorMessage = error.message
        if (errorCode == "auth/weak-password") {
          alert("The password is too weak.")
        } else {
          alert(errorMessage)
        }
        throwError(() => {
          return error
        })
      })
*/
  }

  signInWithEmailAndPassword(email: string, password: string) {
    return afSignInWithEmailAndPassword(this.auth, email, password)
      .then((userCredential) => {
        if (userCredential?.user) {
          sendEmailVerification(userCredential.user).then().catch(error => console.log(error))
          // this.updateUserAccount(userCredential.user)
        }
      })
/*
      .catch((error) => {
        // Handle Errors here.
        const errorCode = error.code
        const errorMessage = error.message
        switch (errorCode) {
          case "auth/invalid-email":
          case "auth/user-disabled":
          case "auth/user-not-found":
            alert(errorMessage)
            break
          case "auth/wrong-password":
            alert("Wrong password.")
            break
          default:
            alert("unkown error: " + errorCode + " - " + errorMessage)
        }
        alert("all errors: " + errorCode + " - " + errorMessage)
        throwError(() => error)
      })
*/
  }

  googleSignInWithPopup() {
    return afSignInWithPopup(this.auth, new GoogleAuthProvider())
  }

  githubLogin() {
    return afSignInWithPopup(this.auth, new GithubAuthProvider())
  }

  facebookLogin() {
    return afSignInWithPopup(this.auth, new FacebookAuthProvider())
  }

  twitterLogin() {
    return afSignInWithPopup(this.auth, new TwitterAuthProvider())
  }

}
