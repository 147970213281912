import { createActionGroup, emptyProps, props } from "@ngrx/store"
import { Group, Item } from "../content/content.model"
import { CheckoutSessionLineItem } from "../stripe/stripe.model"

export const CartActions = createActionGroup({
  source: "Cart",
  events: {
    "init": emptyProps(),

    "Load Carts": emptyProps(),
    "Load Carts Success": props<{ data: unknown }>(),
    "Load Carts Failure": props<{ error: unknown }>(),

    "set line items": props<{ checkoutLineItems: CheckoutSessionLineItem[] }>(),
    "add": props<{ item: Item, group: Group, quantity: number }>(),
    "remove": props<{ itemId: string }>(),
    "change quantity": props<{ itemId: string, quantity: number }>(),
  }
})
