import { ChangeDetectionStrategy, Component, effect, inject, input } from "@angular/core"
import { Store } from "@ngrx/store"
import * as AppStore from "../../store"
import { GroupCardComponent } from "./group-card.component"
import { ButtonComponent } from "../button/button.component"
import { RouterLink } from "@angular/router"
import { InheritDirective } from "../../directives/inherit.directive"
import { GroupService } from "./group.service"
import { GroupView } from "./group.model"

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    GroupCardComponent,
    ButtonComponent,
    RouterLink,
    InheritDirective,
],
  providers: [],
  standalone: true,
  template: `
    <div class="row">
      @for (groupView of groupsView(); track index; let index = $index) {
        <div style="width: 266px; height: 176px">
          <e2e-group-card [groupView]="groupView"/>
        </div>
      }
    </div>
    
    <!--
    <a
      *ngFor="let group of groups$ | async"
      class="col-3 surface-card shadow-2 p-3 border-round"
      [routerLink]="group.menuItem.routerLink"
      >
      <e2e-group
        [group]="group"
        style="display: contents"
      ></e2e-group>
    </a>
    -->
    
    <!--
    <div class="flex justify-content-between mb-3">
      <div>
        <span class="block text-500 font-medium mb-3">Orders</span>
        <div class="text-900 font-medium text-xl">152</div>
      </div>
      <div class="flex align-items-center justify-content-center bg-blue-100 border-round"
        style="width:2.5rem;height:2.5rem">
        <i class="pi pi-shopping-cart text-blue-500 text-xl"></i>
      </div>
    </div>
    <span class="text-green-500 font-medium">24 new</span>
    <span class="text-500">since last visit</span>
    -->
    `,
  selector: "e2e-groups",
})
export class GroupsComponent {
  groupsView = input.required<GroupView[]>()
}
