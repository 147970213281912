import { Stripe } from "stripe"

export const FeatureKey = "stripe"

export interface State {
  customerId: string | null
}

export interface CustomerDoc {
  email: string
  stripeId: string
  stripeLink: string
}

export interface CheckoutSessionLineItem extends Stripe.Checkout.SessionCreateParams.LineItem {
  price_data: {
    currency: string
    product_data: {
      name: string
      description: string // displays in checkout, include unit measurement (each, per pair, per box, etc)
      images: string[]
      metadata: {
        itemId: string
        itemSku: string
      }
    },
    unit_amount: number
  },
  adjustable_quantity: {
    enabled: boolean
    minimum: number
    maximum: number
  },
  quantity: number
}

/*
export interface SessionParams {
  payment_method_types: string[] // ["card"]
  line_items: LineItem[],
  mode: string // "payment"
  success_url: string // "https://example.com/success"
  cancel_url: string // "https://example.com/cancel"
}
*/

/*
export interface LineItem {
  price_data: {
    currency: string // "usd"
    product_data: {
      name: string // "T-shirt"
    },
    unit_amount: number // 2000
  },
  quantity: number // 1
}
*/
