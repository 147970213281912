import { Component, inject } from "@angular/core"
import { Store } from "@ngrx/store"
import * as AppStore from "../store"
import { HttpClient, HttpHeaders } from "@angular/common/http"
import { StripeService } from "ngx-stripe"
import { Stripe } from "stripe"
import { catchError, switchMap } from "rxjs/operators"
import { environment } from "../../environments/environment"
import { CheckoutSessionLineItem } from "../store/stripe/stripe.model"
import { BadgeModule } from "primeng/badge"
import { EMPTY, of } from "rxjs"
import { InheritDirective } from "../directives/inherit.directive"
import { HlmButtonDirective } from "@spartan-ng/ui-button-helm"

@Component({
  imports: [
    BadgeModule,
    InheritDirective,
    HlmButtonDirective,
  ],
  providers: [],
  standalone: true,
  template: `
    <button
      hlmBtn
      style="color: white"
      variant="blue"
      size="medium"
      [disabled]="!afUser() || !customerId() || !checkoutLineItems().length"
      (click)="checkout(customerId(), checkoutLineItems())"
    >
      Checkout
      <p-badge [value]="checkoutLineItems().length.toString()" severity="danger"></p-badge>
    </button>
  `,
  selector: "e2e-checkout",
})
export class CheckoutComponent {
  private store = inject(Store<AppStore.State>)
  private httpClient = inject(HttpClient)
  private stripeService = inject(StripeService)

  customerId = this.store.selectSignal(AppStore.stripeCustomerId)
  checkoutLineItems = this.store.selectSignal(AppStore.checkoutLineItems)
  afUser = this.store.selectSignal(AppStore.afUser)

  checkout(customerId: string | null, checkoutLineItems: CheckoutSessionLineItem[]) {
    if (customerId && checkoutLineItems.length) {
      const httpPostBody = {
        headers: new HttpHeaders({}),
        sessionParams: {
          // billing_address_collection: "required",
          custom_fields: [{
            key: "specialInstructions",
            label: {
              type: "custom",
              custom: "Special Instructions"
            },
            type: "text"
          }],
          customer: customerId,
          customer_update: {
            address: "auto",
            name: "auto",
            shipping: "auto"
          },
          // customer_creation: "always",
          // customer_email: "lowell@digitalfarrier.com",
          line_items: checkoutLineItems,
          mode: "payment",
          payment_method_types: ["card"],
          phone_number_collection: {
            enabled: true
          },
          cancel_url: environment.baseUrl + location.pathname,
          success_url: environment.baseUrl + "/",
          shipping_address_collection: {
            allowed_countries: ["US"]
          },
          // return_url: environment.hostingUrl + "/checkout",
          // tax_rates: [TAX_RATE_ID],
          // dynamic_tax_rates: [DYNAMIC_TAX_RATE_ID],
          ui_mode: "hosted",
        }
      }
      // console.log(httpPostBody)
      this.httpClient.post<Stripe.Checkout.Session>(environment.baseUrl + "/api-v1/create-checkout-session", httpPostBody)
        .pipe(
          switchMap(session => {
            if (session.id) {
              return this.stripeService.redirectToCheckout({ sessionId: session.id })
            }
            return of({ error: { message: "session id is missing from Stripe.Checkout.Session cloudFunction" } })
          }),
          catchError(err => {
            console.error(err)
            return EMPTY
          })
        )
        .subscribe(result => {
          // If `redirectToCheckout` fails due to a browser or network
          // error, you should display the localized error message to your
          // customer using `error.message`.
          if (result?.error) {
            alert(result.error.message)
          }
        })
    }
  }

}
