import { inject, Injectable } from "@angular/core"
import { Actions, createEffect, ofType } from "@ngrx/effects"
import { catchError, map, switchMap, tap } from "rxjs/operators"
import { from, of } from "rxjs"
import { AuthService } from "../../services/auth.service"
import { UserActions } from "./user.actions"

@Injectable()
export class UserEffects {
  private authService = inject(AuthService)
  private actions$ = inject(Actions)

  login$ = createEffect(() => this.actions$.pipe(
    ofType(UserActions.userLogin),
    switchMap(({ username, password }) =>
      from(
        this.authService.signInWithEmailAndPassword(username, password)
      ).pipe(
        tap(result => console.log(result)),
        map(token => UserActions.loginSuccess({ token: "token" })),
        catchError(error => of(UserActions.loginFailure({ error })))
      )
    )
  ))

}
