import { ChangeDetectionStrategy, Component } from "@angular/core"
import { UserAvatarComponent } from "./user-avatar.component"
import { ToolbarModule } from "primeng/toolbar"
import { InheritDirective } from "../directives/inherit.directive"

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    UserAvatarComponent,
    ToolbarModule,
    InheritDirective,
  ],
  providers: [],
  standalone: true,
  template: `
    <p-toolbar>
      <div class="w-full">
        <div class="flex justify-center">
          <img
            class="p-2"
            style="max-width: 800px"
            alt=""
            src="../../assets/rtduggan-header-logo.jpg"
            height="152"
            width="800"
          />
        </div>
      </div>
    </p-toolbar>
  `,
  selector: "e2e-toolbar-banner",
})
export class ToolbarBannerComponent {

}
