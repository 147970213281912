import { createFeature, createReducer, on } from "@ngrx/store"
import { CartActions } from "./cart.actions"
import { FeatureKey, State } from "./cart.model"
import { CheckoutSessionLineItem } from "../stripe/stripe.model"
import { clone } from "lodash-es"

const initialState: State = {
  checkoutLineItems: []
}

export const cartReducer = createReducer(
  initialState,

  on(CartActions.setLineItems, (state, { checkoutLineItems }) => {
    return {
      ...state,
      checkoutLineItems
    }
  }),

  on(CartActions.add, (state, { item, group, quantity }) => {
    const checkoutSessionLineItem = {
      price_data: {
        currency: "usd",
        product_data: {
          name: item.title,
          description: item.description, // include unit measurement, eg. each, per pair, per box, etc
          images: [item.images[0] || group.images[0] || []] as string[],
          metadata: {
            itemId: item.sku,
            itemSku: item.sku,
          }
        },
        unit_amount: item.retail * 100,
      },
      adjustable_quantity: {
        enabled: true,
        minimum: 0,
        maximum: 999999,
      },
      quantity
    }

    const checkoutLineItems = clone(state.checkoutLineItems) as CheckoutSessionLineItem[]
    const existingLineItemIndex = checkoutLineItems
      .findIndex(checkoutLineItem => checkoutLineItem.price_data.product_data.metadata.itemSku === item.sku)
    if (existingLineItemIndex === -1) { // add new line item
      checkoutLineItems.push(checkoutSessionLineItem)
    }
    if (existingLineItemIndex !== -1) { // add quantity to existing line item
      checkoutLineItems[existingLineItemIndex].quantity = checkoutLineItems[existingLineItemIndex].quantity + checkoutSessionLineItem.quantity
    }

    localStorage.setItem("checkoutLineItems", JSON.stringify(checkoutLineItems))

    return {
      ...state,
      checkoutLineItems
    }
  }),

  on(CartActions.remove, (state, { itemId }) => {
    const checkoutLineItems = state.checkoutLineItems

    return {
      ...state,
      checkoutLineItems
    }
  }),
  on(CartActions.changeQuantity, (state, { itemId, quantity }) => {
    const checkoutLineItems = state.checkoutLineItems

    return {
      ...state,
      checkoutLineItems
    }
  }),

/*
  on(CartActions.loadCarts, state => state),
  on(CartActions.loadCartsSuccess, (state, action) => state),
  on(CartActions.loadCartsFailure, (state, action) => state),
*/
)

export const cartFeature = createFeature({
  name: FeatureKey,
  reducer: cartReducer,
})

