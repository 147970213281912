import { createActionGroup, emptyProps, props } from "@ngrx/store"
import { User } from "@angular/fire/auth"

export const StripeActions = createActionGroup({
  source: "Stripe",
  events: {
    "Load Stripes": emptyProps(),
    "Load Stripes Success": props<{ data: unknown }>(),
    "Load Stripes Failure": props<{ error: unknown }>(),

    "get customer id":  props<{ afUser: Partial<User> | null }>(),
    "set customer id": props<{ customerId: string | null }>(),
  }
})
