import { ChangeDetectionStrategy, Component, inject } from "@angular/core"
import { CommonModule } from "@angular/common"
import { TooltipModule } from "primeng/tooltip"
import { Store } from "@ngrx/store"
import * as AppStore from "../store"
import { LetDirective } from "@ngrx/component"
import { MenubarModule } from "primeng/menubar"
import { InputTextModule } from "primeng/inputtext"
import { ToolbarModule } from "primeng/toolbar"
import { InheritDirective } from "../directives/inherit.directive"
import { FaIconComponent } from "@fortawesome/angular-fontawesome"
import { faUser } from "@fortawesome/pro-solid-svg-icons"

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    TooltipModule,
    LetDirective,
    MenubarModule,
    InputTextModule,
    ToolbarModule,
    InheritDirective,
    FaIconComponent,
  ],
  providers: [],
  standalone: true,
  template: `
    <div class="row">
      @if (loggedOut()) {
        <a
          pTooltip="Log In"
          routerLink="/login"
        >
          <fa-icon [icon]="faUser"/>
        </a>
      }
      @if (loggedIn()) {
        <a
          pTooltip="Account"
          routerLink="/login"
        >
          @if (profileImagePhotoURL(); as profileImage) {
            <div style="width: 45px">
              <img
                style="clip-path: circle(50%)"
                alt=""
                [src]="profileImage"
              />
            </div>
          } @else {
            <i class="pi pi-user"></i>
          }
        </a>
      }
    </div>
  `,
  selector: "e2e-user-avatar",
})
export class UserAvatarComponent {
  private store = inject(Store<AppStore.State>)

  loggedIn = this.store.selectSignal(AppStore.loggedIn)
  loggedOut = this.store.selectSignal(AppStore.loggedOut)
  profileImagePhotoURL = this.store.selectSignal(AppStore.profileImagePhotoURL)

  protected readonly faUser = faUser
}
