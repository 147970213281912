import { inject, Injectable } from "@angular/core"
import { collection, doc, Firestore as AngularFirestore, writeBatch } from "@angular/fire/firestore"
import { ContentDoc, ContentDocs, ContentType, Group, Groups, Item, Items } from "./content.model"
import { MenuItem } from "primeng/api"

@Injectable({
  providedIn: "root",
})
export class ContentService {
  private afs = inject(AngularFirestore)

  saveContentDocs(contentDocs: ContentDocs) {
    const batch = writeBatch(this.afs)
    Object.entries(contentDocs)
      .map(contentDocEntry => {
        return batch.set(
          doc(collection(this.afs, "content"), contentDocEntry[0]),
          { ...contentDocEntry[1] },
          { merge: true }
        )
      })
    return batch.commit()
  }

  addContentToDocs(contentType: ContentType, groupOrItemOrMenuItems: Group | Item | MenuItem[], contentDocs: ContentDocs) {
    const docs = { ...contentDocs }
    let docAdded = false
    const groupName = contentType
      .replace("ROOT_", "")
      .toLowerCase()
      .replace("_", "-")
    switch (contentType) {
      case ContentType.PRODUCT:
      case ContentType.PAGE:
        break
      case ContentType.MENU: {
        const contentDocId = groupName + "-0"
        docs[contentDocId] = {
          id: contentDocId,
          content: groupOrItemOrMenuItems as MenuItem[],
          type: ContentType.MENU
        } as ContentDoc
      }
        break
      case ContentType.ROOT_GROUP:
      case ContentType.GROUP:
      case ContentType.ITEM: {
        const content = groupOrItemOrMenuItems as Group | Item
        if (!docAdded) {
          Object.entries(docs).map(contentDocEntry => {
            const contentDoc = contentDocEntry[1]
            const contentMap = contentDoc.content as Groups | Items
            if (contentMap[content.sku]) {
              contentMap[content.sku] = content
              docs[contentDocEntry[0]] = contentDoc
              docAdded = true
            }
          })
        }
        if (!docAdded) {
          Object.entries(docs).map(contentDocEntry => {
            const contentMap = contentDocEntry[1].content as Groups | Items
            // 420kb seems to be max size
            // 1400 items are 420kb without images and descriptions, might double if all get descriptions
            // 750 groups are 350kb without images and descriptions, probably won't get many descriptions
            let limit = 0
            switch (contentType) {
              case ContentType.ITEM:
                limit = 800
                break
              case ContentType.GROUP:
              case ContentType.ROOT_GROUP:
                limit = 400
            }
            if (Object.keys(contentMap).length < limit) { // 420kb seems to be max size
              contentMap[content.sku] = content
              docs[contentDocEntry[0]] = contentDocEntry[1]
              docAdded = true
            }
          })
        }
        /**
         * Add fragment to a new group if none of the existing groups have room for it.
         */
        if (!docAdded) {
          const contentDocId = groupName + "-" + Object.keys(docs).length.toString()
          docs[contentDocId] = {
            id: contentDocId,
            content: {
              [content.sku]: content
            },
            type: contentType
              .replace("ROOT_", "")
          } as ContentDoc
        }
      }
        break
    }
    return docs
  }


}
