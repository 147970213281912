import { createActionGroup, props } from "@ngrx/store"
import { ImportDataRaw, ImportedGroupRow, ImportedItemRow } from "./import.model"
import { MenuItem } from "primeng/api"
import { Groups, Items } from "../content/content.model"

export const ImportActions = createActionGroup({
  source: "Import",
  events: {
    "process imported data": props<{ data: ImportDataRaw[] }>(),
    "build groups and items": props<{ importedGroupRows: ImportedGroupRow[], importedItemRows: ImportedItemRow[] }>(),

    "save groups": props<{ groups: Groups }>(),
    "save items": props<{ items: Items }>(),
    "save menu items": props<{ menuItems: MenuItem[] }>(),

    // "save groups items and menuItems": props<{ groups: Groups, items: Items, menuItems: MenuItem[] }>(),

    // "Import Save Docs": props<{ groupsAndItems: RawItem[] }>(),

    "Imports Success": props<{ data: unknown }>(),
    "Imports Failure": props<{ error: unknown }>(),
  }
})
