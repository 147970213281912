import { createReducer, on } from "@ngrx/store"
import { ContentActions } from "./content.actions"
import {  State } from "./content.model"

const initialState: State = {
  groupDocs: {},
  groups: {},
  itemDocs: {},
  items: {},
  menuDocs: {},
  menuItems: [],
  menuItemDocs: {},
}

export const contentReducer = createReducer(
  initialState,

  on(ContentActions.setMenuItemsFromFirestore, (state, { menuItems, options }) => {
    // if (options.cache) localStorage.setItem("menuItems", JSON.stringify(menuItems))
    return ({
      ...state,
      menuItems
    })
  }),

  on(ContentActions.setGroupsContentFromFirestore, (state, { groups, options }) => {
    const allGroups = { ...state.groups, ...groups }
    Object.values(allGroups).map(groupValue => {
      allGroups[groupValue.sku] = {
        ...groupValue,
        groups: groupValue.groups || []
      }
    })
    // if (options.cache) localStorage.setItem("groups", JSON.stringify(allGroups))
    return ({
      ...state,
      groups: allGroups
    })
  }),

  on(ContentActions.setItemsContentFromFirestore, (state, { items, options }) => {
    const allItems = { ...state.items, ...items }
    Object.values(allItems).forEach(itemValue => {
      allItems[itemValue.sku] = {
        ...itemValue,
        unit: itemValue.unit || "",
        retail: itemValue.retail || 0
      }
    })
    // if (options.cache) localStorage.setItem("items", JSON.stringify(allItems))
    return ({
      ...state,
      items: allItems
    })
  }),

)
