import { Inject, inject, Injectable } from "@angular/core"
import { Actions, createEffect, ofType } from "@ngrx/effects"
import { tap } from "rxjs/operators"
import { collection, doc, Firestore as AngularFirestore, onSnapshot, query, where } from "@angular/fire/firestore"
import { ContentDoc, ContentType, Groups, Items } from "./content.model"
import { MenuItem } from "primeng/api"
import { Store } from "@ngrx/store"
import * as AppStore from "../../store"
import { ContentActions } from "../../store"
import { LOCAL_STORAGE } from "@ng-web-apis/common"
import { sortByField } from "../../utilities/sort.utility"
import { routerNavigatedAction } from "@ngrx/router-store"
import { RouterState } from "../router/route-serializer"

@Injectable()
export class ContentEffects {
  private actions$ = inject(Actions)
  private afs = inject(AngularFirestore)
  private store = inject(Store<AppStore.State>)

  constructor(
    @Inject(LOCAL_STORAGE)
    private localStorage: Storage,
  ) {
  }

  getMenuItemsFromFirestore = createEffect(() => this.actions$.pipe(
    ofType(ContentActions.init),
    tap(() => {
/*
      const menuItemsString = this.localStorage.getItem("menuItems")
      if (menuItemsString) {
        this.store.dispatch(ContentActions.setMenuItemsFromFirestore({
          menuItems: JSON.parse(menuItemsString),
          options: { cache: false }
        }))
        this.localStorage.removeItem("menuItems")
      }
*/
      onSnapshot(
        doc(this.afs, "content", "menu-0"),
        (snapshot) => {
          const contentDoc = snapshot.data() as ContentDoc | undefined
          const menuItems = contentDoc?.content as MenuItem[] || []
          this.store.dispatch(ContentActions.setMenuItemsFromFirestore({
            menuItems: sortByField("label", menuItems),
            options: { cache: true }
          }))
        }
      )
    })
  ), { dispatch: false })

  getGroupsFromFirestore = createEffect(() => this.actions$.pipe(
    ofType(ContentActions.init),
    tap(() => {
/*
      const groupsString = this.localStorage.getItem("groups")
      if (groupsString) {
        this.store.dispatch(ContentActions.setGroupsContentFromFirestore({
          groups: JSON.parse(groupsString),
          options: { cache: false }
        }))
        this.localStorage.removeItem("groups")
      }
*/
      onSnapshot(
        query(
          collection(this.afs, "content"),
          where("type", "==", ContentType.GROUP)
        ),
        (snapshot) => {
          snapshot.docChanges().forEach(change => {
            const contentDoc = change.doc.data() as ContentDoc
            this.store.dispatch(ContentActions.setGroupsContentFromFirestore({
              groups: contentDoc.content as Groups,
              options: { cache: true }
            }))
          })
        }
      )
    })
  ), { dispatch: false })

  getItemsFromFirestore = createEffect(() => this.actions$.pipe(
    ofType(ContentActions.init),
    tap(() => {
/*
      const itemsString = this.localStorage.getItem("items")
      if (itemsString) {
        this.store.dispatch(ContentActions.setItemsContentFromFirestore({
          items: JSON.parse(itemsString),
          options: { cache: false }
        }))
        this.localStorage.removeItem("items")
      }
*/
      onSnapshot(
        query(
          collection(this.afs, "content"),
          where("type", "==", ContentType.ITEM)
        ),
        (snapshot) => {
          snapshot.docChanges().forEach(change => {
            const contentDoc = change.doc.data() as ContentDoc
            this.store.dispatch(ContentActions.setItemsContentFromFirestore({
              items: contentDoc.content as Items,
              options: { cache: true }
            }))
          })
        }
      )
    })
  ), { dispatch: false })

}
