import { createFeatureSelector, createSelector } from "@ngrx/store"
import { FeatureKey, State } from "./content.model"

const selectState = createFeatureSelector<State>(FeatureKey)

export const groupContentDocs = createSelector(selectState,
  ({ groupDocs }) => groupDocs
)
// export const rootGroups = createSelector(selectState,
//   ({ groups }) => {
//     return Object.entries(groups)
//       .filter(group => group[1].type === ContentType.ROOT_GROUP)
//       .reduce((accumulator: ContentDoc["content"], currentValue) => {
//         accumulator[currentValue[0]] = currentValue[1]
//         return accumulator
//       }, {})
//   })
export const groups = createSelector(selectState, ({ groups }) =>
  groups)
export const groupsAndItems = createSelector(selectState, ({ groups, items }) =>
  ({ ...groups, ...items }))

export const groupDocs = createSelector(selectState, ({ groupDocs }) =>
  groupDocs)
export const itemDocs = createSelector(selectState, ({ itemDocs }) =>
  itemDocs)
export const menuItemDocs = createSelector(selectState, ({ menuItemDocs }) =>
  menuItemDocs)

export const items = createSelector(selectState, ({ items }) =>
  items)
// export const pageDocs = createSelector(selectState, ({ pageDocs }) => pageDocs)
// export const treeValue = createSelector(selectState, ({ treeValue }) => treeValue)
export const menuItems = createSelector(selectState, ({ menuItems }) =>
  menuItems)
// export const editingCategory = createSelector(selectState,
//   ({ editingCategory }) => editingCategory
// )
// export const purge = createSelector(selectState, ({ purge }) => purge)
// export const importContent = createSelector(selectState, ({ importContent }) => importContent)
