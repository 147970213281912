import { inject, Injectable } from "@angular/core"
import { collection, doc, Firestore as AngularFirestore, } from "@angular/fire/firestore"

export function idFromText(name: string) {
  if (name) {
    return name.toLowerCase()
      .replace(/[^a-z0-9]/g, "_") // non-alpha-numerics to underscores... a bit crude
      .replace(/_+_*/g, "_") // multiple underscores to single underscore
  }
  return ""
}

@Injectable({
  providedIn: "root",
})
export class UtilsService {
  private afs = inject(AngularFirestore)

  get createId(): string {
    return doc(collection(this.afs, "_")).id
  }

  idsFromNames(names: string[]) {
    return names.map(name => this.idFromName(name))
  }

  idFromName(name: string) {
    return name?.toLowerCase()
      .replace(/[^A-Za-z0-9]/g, "_")
      .replace(/_+_*/g, "_")
  }

  enumKeys<O extends object, K extends keyof O = keyof O>(obj: O): K[] {
    return Object.keys(obj).filter(k => Number.isNaN(+k)) as K[]
  }

  expandedLog(item: any, maxDepth = 100, depth = 0) {
    if (depth > maxDepth) {
      console.log(item)
      return
    }
    if (typeof item === "object" && item !== null) {
      Object.entries(item).forEach(([key, value]) => {
        // console.group(key + " : " + (typeof value))
        console.group(key)
        this.expandedLog(value, maxDepth, depth + 1)
        console.groupEnd()
      })
    } else {
      console.log(item)
    }
  }

  breakArrayIntoGroups(data: unknown[], maxPerGroup: number) {
    const groups = []
    for (let index = 0; index < data.length; index += maxPerGroup) {
      groups.push(data.slice(index, index + maxPerGroup))
    }
    return groups
  }

  contentDataTransform(contentData: any) {
    const data = JSON.parse(JSON.stringify(contentData))
    // delete data["id"]

    // console.log(data.id)
    // console.log(JSON.parse(JSON.stringify(data)))

    const l = []
    l.push("")

    if (data.categoryType === "ROOT") data.type = "ROOT_GROUP"

    if (data.type === "CATEGORY") data.type = "GROUP"

    /**
     * move types into settings object
     */

    /**
     * add layer if undefined
     * remove boxModel
     */
    /*
        if (data.settings.location === undefined) {
          data.settings.location = {
            lat: null,
            lng: null,
          }
        }
    */

    /**
     * add svgGraphic to image field
     */
    /*
        if (data.rows) {
          for (const row of Object.keys(data.rows)) {
            if (data.rows[row].field === FieldType.IMAGE) {
              const imageField = data.rows[row] as Image
              if (imageField.svgGraphics) {
                if (imageField.filePath || imageField.fileType || imageField.alt) {
                  imageField.filePath = ""
                  imageField.fileType = ""
                  imageField.alt = ""
                  this.writeContent(data, data.id).then()
                }
              }
            }
          }
        }
    */

    /**
     * rename svgGraphicType to svgGraphics
     */
    /*
        if (data.rows) {
          for (const row of Object.keys(data.rows)) {
            if (data.rows[row].field === FieldType.IMAGE) {
              const imageField = data.rows[row] as Image
              if (imageField.svgGraphicType) {
                imageField.svgGraphics = imageField.svgGraphicType
                delete imageField.svgGraphicType
              }
            }
          }
        }
    */

    /**
     * finalize tne data object
     */

    // console.log(data.rows)

    /**
     * output logMessages if they exist
     */
    // if (l.length) {
    //   console.log(l)
    // }

    return data
  }

}
