import { createReducer, on } from "@ngrx/store"
import { UserActions } from "./user.actions"
import { State } from "./user.model"

const initialState: State = {
  error: null,
  loading: false,
  profile: null,
  record: null,
  afUser: null,
  loggedIn: null,
}

export const userReducer = createReducer(initialState,

  on(UserActions.authStateChange, (state, { afUser }) => ({
    ...state,
    afUser: afUser,
    error: afUser === null ? "error" : null,
    loading: false,
    loggedIn: !!afUser,
    profile: null, // placeholder
    record: null, // placeholder
  })),
/*
  on(UserActions.authenticated, (user, { afUser }) => ({
    afUser: afUser,
    error: null,
    loading: false,
    loggedIn: true,
    profile: null, // placeholder
    record: null, // placeholder
  })),
  on(UserActions.notAuthenticated, (user) => ({
    afUser: null,
    error: "error", // placeholder
    loading: false,
    loggedIn: false,
    profile: null,
    record: null,
  })),
*/

  on(UserActions.userLogin, user => ({
    ...user,
    isLoading: true
  })),
  on(UserActions.loginSuccess, (user, { token }) => ({
    ...user,
    isLoading: false
  })),
  on(UserActions.loginFailure, (user, { error }) => ({
    ...user,
    error: error,
    isLoading: false,
  })),
  on(UserActions.loadingStart, (user) => ({
    ...user,
    loading: true
  })),
  on(UserActions.loadingFinish, (user) => ({
    ...user,
    loading: false
  })),
  on(UserActions.loadingError, (user, { error}) => ({
    ...user,
    error: error,
    loading: false
  })),

)
