import { ChangeDetectionStrategy, Component, inject } from "@angular/core"
import { GroupsComponent } from "./groups.component"
import { ItemsComponent } from "./items.component"
import { InheritDirective } from "../../directives/inherit.directive"
import { GroupService } from "./group.service"
import { BreadcrumbComponent } from "../breadcrumb/breadcrumb.component"
import { HlmInputDirective } from "@spartan-ng/ui-input-helm"

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    GroupsComponent,
    ItemsComponent,
    InheritDirective,
    BreadcrumbComponent,
    HlmInputDirective,
  ],
  standalone: true,
  template: `
    <div class="column" style="align-items: flex-start">
      <div class="row">
        <e2e-breadcrumb/>
        <input
          hlmInput
          type="text"
          size="medium"
          placeholder="Search"
        />
      </div>
      <!--      @if (group()?.description) {-->
      <!--        <div>-->
      <!--          {{ group()?.title }}: {{ group()?.description }}-->
      <!--        </div>-->
      <!--      }-->

      @if (!groupItems().length && groupsView().length) {
        <e2e-groups [groupsView]="groupsView()"/>
      }
      @if (groupItems().length && group(); as group) {
        <e2e-items 
          [group]="group" 
          [groupItems]="groupItems()"
        />
      }
    </div>
  `,
  selector: "e2e-products-page",
})
export class ProductsPageComponent {
  private groupService = inject(GroupService)

  group = this.groupService.group
  groupItems = this.groupService.groupItemsSorted
  groupsView = this.groupService.groupsViewSorted

}
