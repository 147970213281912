import { Role } from "../../services/auth.service"
import { User } from "@angular/fire/auth"

export const FeatureKey = "user"

export interface State {
  error: string | null
  loading: boolean
  loggedIn: boolean | null
  profile: UserProfile | null
  record: UserRecord | null
  afUser: Partial<User> | null
}

export type CreateUserRequest = {
  displayName: string
  password: string
  email: string
  roles: Role[]
}

export type UpdateUserRequest = {
  roles: Role[]
  uid: string
}

// export interface AfAuthUser {
//   uid: string
//   displayName: string
//   email: string
//   emailVerified: boolean
//   photoURL: string
// }

export interface UserAccount {
  emailVerified: string | null
  email: string | null
  displayName: string | null
  isAnonymous: boolean
  phoneNumber: string | null
  photoURL: string | null
  providerId: string
  uid: string
  tenantId: string | null
}

export interface UserProfile {
  name: string
}

export interface UserRecord {
  uid: string // "s5l6ffFZ1nd1JJASOa3X08j0q4g1",
  email: string // "lowell@digitalfarrier.com",
  emailVerified: true,
  displayName: string // "Lowell Johnson",
  photoURL: string // "https://lh3.googleusercontent.com/a-/AOh14GicwKgQUifTR2ACb0BKE2tFn3npLA1NcodrJEEPtKI=s96-c",
  disabled: false,
  metadata: {
    lastSignInTime: string // "Mon, 26 Jun 2023 04:57:14 GMT",
    creationTime: string // "Mon, 31 May 2021 23:44:39 GMT",
    lastRefreshTime: string // "Wed, 26 Jul 2023 11:47:36 GMT"
  },
  customClaims: {
    roles: Role[]
  },
  tokensValidAfterTime: string // "Mon, 31 May 2021 23:44:39 GMT",
  providerData: ProviderData[]
}

export interface ProviderData {
  uid: string // "114863180628058934403",
  displayName: string // "Lowell Johnson",
  email: string // "lowell@digitalfarrier.com",
  photoURL: string // "https://lh3.googleusercontent.com/a/AAcHTtc0lx9RAteawA_9aGH-dM8b1I7X9YHbcI78G9VbTrI=s96-c",
  providerId: string // "google.com"
}
