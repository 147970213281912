import { inject, Injectable } from "@angular/core"
import { Actions, createEffect, ofType } from "@ngrx/effects"
import { CartActions } from "./cart.actions"
import { Action, Store } from "@ngrx/store"
import * as AppStore from "../index"
import { ContentActions } from "../index"
import { CheckoutSessionLineItem } from "../stripe/stripe.model"
import { from } from "rxjs"
import { switchMap } from 'rxjs/operators';

@Injectable()
export class CartEffects {
  private actions$ = inject(Actions)
  private store = inject(Store<AppStore.State>)

  setLineItems$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ContentActions.setItemsContentFromFirestore),
      switchMap(({ items }) => {
        const checkoutLineItemsString = localStorage.getItem("checkoutLineItems")
        const actions: Action[] = []
        if (checkoutLineItemsString && items) {
          const checkoutLineItemsFromStorage = JSON.parse(checkoutLineItemsString) as CheckoutSessionLineItem[]
          const checkoutLineItems: CheckoutSessionLineItem[] = []
          checkoutLineItemsFromStorage.map(checkoutLineItem => {
            const lineItem = items[checkoutLineItem.price_data.product_data.metadata.itemSku]
            if (lineItem) {
              checkoutLineItems.push({
                ...checkoutLineItem,
                price_data: {
                  ...checkoutLineItem.price_data,
                  product_data: {
                    ...checkoutLineItem.price_data.product_data,
                    description: lineItem.description || checkoutLineItem.price_data.product_data.description,
                    name: lineItem.title,
                  },
                  unit_amount: lineItem.retail * 100,
                },
              })
            }
          })
          if (checkoutLineItems.length) {
            actions.push(CartActions.setLineItems({ checkoutLineItems }))
          }
        }
        return from(actions)
      }),
    )
  )

  /*
    loadCarts$ = createEffect(() => {
      return this.actions$.pipe(
        ofType(CartActions.loadCarts),
        concatMap(() =>
          /!** An EMPTY observable only emits completion. Replace with your own observable API request *!/
          EMPTY.pipe(
            map(data => CartActions.loadCartsSuccess({ data })),
            catchError(error => of(CartActions.loadCartsFailure({ error }))))
        )
      )
    })
  */

}
