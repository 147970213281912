import { inject, Injectable } from "@angular/core"
import { Actions, createEffect, ofType } from "@ngrx/effects"
import { map, switchMap, withLatestFrom } from "rxjs/operators"
import { ImportActions } from "./import.actions"
import { ImportedGroupRow, ImportedItemRow } from "./import.model"
import { MenuItem } from "primeng/api"
import { ContentType, Groups, Items } from "../content/content.model"
import * as AppStore from "../index"
import { ContentActions } from "../index"
import { defer, from, Observable } from "rxjs"
import { Action, Store } from "@ngrx/store"
import { ContentService } from "../content/content.service"
import { idFromText } from "../../services/utils.service"
import { cloneDeep } from "lodash-es"

@Injectable()
export class ImportEffects {
  private actions$ = inject(Actions)
  private store = inject(Store<AppStore.State>)
  private contentService = inject(ContentService)

  previousRow: ImportedItemRow | undefined = undefined

  /**
   * Removes certain items from the local storage,
   * processes each row of imported data,
   * and emits an action to build groups and items.
   */
  processImportedData$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(ImportActions.processImportedData),
    map(({ data }) => {
      localStorage.removeItem("menuItems")
      localStorage.removeItem("groups")
      localStorage.removeItem("items")

      const previousRow = this.previousRow

      // process groups and items separately...
      // maybe process all rows, then separate into groups and items
      // const importedRow: ImportedGroupRow | ImportedItemRow = data.map...

      const importedRows = data
        // .slice(0, 3300)
        .map(row => {
          const importedRow: ImportedGroupRow | ImportedItemRow = {
            docId: "",
            description: row.description.toString(),
            groupedParents: this.getParentGroups([row.group1, row.group2, row.group3, row.group4]),
            images: row.images
              .split("*")
              .map(group => group
                .replace(/^\s+/, "") // trim leading whitespace(s)
                .replace(/\s+$/, "") // trim trailing whitespace(s)
                .toString()
              )
              .filter(image => !!image),
            manufacturer: row.manufacturer.toString() || previousRow?.manufacturer || "",
            retail: row.retail,
            sku: row.sku.toString(),
            title: row.title.toString(),
            unit: row.unit.toString() || previousRow?.unit || "",
          }

          // if (!importedRow.groupedParents.length)
          //   importedRow.groupedParents = previousRow?.groupedParents || []
          this.previousRow = importedRow

          return importedRow
        })
        .filter(importedRow => !!importedRow.sku)

      const importedGroupRows: ImportedGroupRow[] = importedRows
        .filter(importedRow => !importedRow.retail && importedRow.groupedParents.length === 1)
        .map(importedRow => {
          const importedGroupRow: ImportedGroupRow = {
            docId: "",
            description: importedRow.description,
            groupRefs: [],
            images: importedRow.images,
            itemRefs: [],
            groups: importedRow.groupedParents[0],
            sku: importedRow.sku,
            title: importedRow.groupedParents[0].slice(-1)[0]
          }
          return importedGroupRow
        })

      const importedItemRows: ImportedItemRow[] = importedRows
        .filter(importedRow => !!importedRow.retail && importedRow.groupedParents.length)

      console.log("importedRows", importedRows.length)
      console.log("importedRows", importedRows)
      console.log("importedGroupRows", importedGroupRows.length)
      console.log("importedGroupRows", importedGroupRows)
      console.log("importedItemRows", importedItemRows.length)
      console.log("importedItemRows", importedItemRows)


      return ImportActions.buildGroupsAndItems({ importedGroupRows, importedItemRows })
    })
  ))

  /**
   * Filters the importedRows based on certain conditions to obtain importedGroupRows and importedItemRows.
   * Then performs transformations on the importedGroupRows and importedItemRows
   * to generate groupRows and groups respectively.
   * Finally, it returns an action to save the generated groups.
   */
  buildGroups$ = createEffect(() => this.actions$.pipe(
    ofType(ImportActions.buildGroupsAndItems),
    map(({ importedGroupRows, importedItemRows }) => {

      /**
       * build groups object
       * key by "sku"
       * add groupRefs to groups
       * add itemRefs to groups
       */
      const groups: Groups = {}

      importedGroupRows
        .forEach(group => {
          // add group to groups if it does not exist yet
          // this ensures every group will get added to groups
          if (!groups[group.sku]) groups[group.sku] = { ...group }

          const parentGroupString = group.groups.slice(0, -1).join(" ")
          const parentGroupIndex = importedGroupRows
            .findIndex(groupRow => groupRow.groups.join(" ") === parentGroupString)

          if (parentGroupIndex !== -1) {
            const parentGroup = importedGroupRows[parentGroupIndex]
            // add parentGroup to groups if it does not exist yet
            if (!groups[parentGroup.sku]) groups[parentGroup.sku] = { ...parentGroup }
            // add sku to parent group's groupRef
            groups[parentGroup.sku].groupRefs.push(group.sku)
          }
        })

      /**
       * add itemRefs to groups
       * each item has a groupedParents array of group arrays
       */
      const importErrors: string[][][] = []
      importedItemRows
        .forEach(itemRow => {
          itemRow.groupedParents
            .forEach(group => {

              const parentGroupString = group.join(" ")
              const parentGroupIndex = importedGroupRows
                .findIndex(groupRow => groupRow.groups.join(" ") === parentGroupString)
              if (parentGroupIndex === -1) {
                importErrors.push(itemRow.groupedParents)
              }
              if (parentGroupIndex !== -1) {
                const parentGroup = importedGroupRows[parentGroupIndex]
                if (!groups[parentGroup.sku]) groups[parentGroup.sku] = cloneDeep(parentGroup)
                // add sku to the parent group's itemRef
                groups[parentGroup.sku].itemRefs.push(itemRow.sku)
              }
            })
        })

      console.error("importErrors", importErrors.length)
      console.error("importErrors", importErrors)

      if (importErrors.length) {
        alert(importErrors.length + " errors. Review errors in console")
      }

      console.log("groups", Object.keys(groups).length)
      // console.log("groups", groups)

      return ImportActions.saveGroups({ groups })
    })
  ))

  /**
   * Listens to actions of type ImportActions.buildGroupsAndItems
   * maps the imported rows to build items.
   * The resulting items are then saved using the ImportActions.saveItems action.
   */
  buildItems$ = createEffect(() => this.actions$.pipe(
    ofType(ImportActions.buildGroupsAndItems),
    map(({ importedItemRows }) => {

      // const importedItemRows = importedRows
      //   .filter(row => row.parentGroups.length && row.sku && row.retail)

      /**
       *  key by "sku"
       */
      const items = importedItemRows
        .reduce((acc: Items, item) => ({
          ...acc,
          [item.sku]: {
            docId: "",
            description: item.description,
            images: item.images,
            manufacturer: item.manufacturer,
            retail: item.retail,
            sku: item.sku,
            title: item.title,
            unit: item.unit
          }
        }), {}) as Items

      console.log("items", Object.keys(items).length)
      // console.log("items", items)

      return ImportActions.saveItems({ items })
    })
  ))

  /**
   * Filters for actions of type `ImportActions.saveGroups` and maps the emitted action object to build menu items.
   * The built menu items are returned as an array of `MenuItem` objects.
   * The `buildMenuItem` function is used recursively to build menu items for each group and their child groups.
   * The `console.log` statement outputs the length of the menu items array to the console.
   * Finally, the built menu items are returned as an action object of type `ImportActions.saveMenuItems`.
   */
  buildMenuItems$ = createEffect(() => this.actions$.pipe(
    ofType(ImportActions.saveGroups),
    map(({ groups }) => {

      const menuItems = Object.values(groups)
        .filter(group => group.groups.length === 1) // rootGroups
        .map(group => {
          const menuItem: MenuItem = {
            label: group.title,
            routerLink: "/" + idFromText(group.groups[0]),
          }
          return menuItem
        }) as MenuItem[]

      console.log("menuItems", menuItems.length)
      console.log("menuItems", menuItems)

      return ImportActions.saveMenuItems({ menuItems })
    })
  ))


  /**
   * The side effect saves the groups data to the content service.
   */
  saveGroups$ = createEffect(() => this.actions$.pipe(
    ofType(ImportActions.saveGroups),
    withLatestFrom(this.store.select(AppStore.groupDocs)),
    switchMap(([{ groups }, groupDocs]) => {
      const contentDocs = Object.values(groups)
        .reduce((contentDocsAccumulator, group) => {
          return this.contentService
            .addContentToDocs(ContentType.GROUP, group, contentDocsAccumulator)
        }, groupDocs)
      return defer(() => from(this.contentService.saveContentDocs(contentDocs)))
        .pipe(map(() => ContentActions.saved()))
    })
  ))

  /**
   * When this effect is triggered by the action `ImportActions.saveItems`, it will handle the saving logic.
   * It will retrieve the current `itemDocs` from the store using `AppStore.itemDocs` selector.
   * Then, it will iterate over the `items` and add their content to the `itemDocs` using `contentService.addContentToDocs`.
   * After modifying the `itemDocs`, it will save the new content using `contentService.saveContentDocs`.
   * Finally, it will dispatch the `ContentActions.saved()` action to signal that the items have been saved successfully.
   */
  saveItems$ = createEffect(() => this.actions$.pipe(
    ofType(ImportActions.saveItems),
    withLatestFrom(this.store.select(AppStore.itemDocs)),
    switchMap(([{ items }, itemDocs]) => {
      const contentDocs = Object.values(items)
        .reduce((itemDocsAccumulator, item) => {
          return this.contentService
            .addContentToDocs(ContentType.ITEM, item, itemDocsAccumulator)
        }, itemDocs)
      return defer(() => from(this.contentService.saveContentDocs(contentDocs)))
        .pipe(map(() => ContentActions.saved()))
    })
  ))

  /**
   * It listens to the `ImportActions.saveMenuItems` action and combines the menu items with the current `menuItemDocs` from the store.
   * It then calls the `contentService.addContentToDocs` method to add the menu items to the menu item documents.
   * Finally, it saves the updated content documents using the `contentService.saveContentDocs` method.
   */
  saveMenuItems$ = createEffect(() => this.actions$.pipe(
    ofType(ImportActions.saveMenuItems),
    withLatestFrom(this.store.select(AppStore.menuItemDocs)),
    switchMap(([{ menuItems }, menuItemDocs]) => {
      const contentDocs = this.contentService
        .addContentToDocs(ContentType.MENU, menuItems, menuItemDocs)
      return defer(() => from(this.contentService.saveContentDocs(contentDocs)))
        .pipe(map(() => ContentActions.saved()))
    })
  ))

  getParentGroups(importedGroups: string[]): string[][] {
    const rawGroups: string[][] = []

    /**
     * split comma separated
     * remove empty groups
     *
     * result will convert each cell, (cell-1 | cell-2 | cell-3 | cell-4), into nested arrays
     * example 1:
     * ("group1-item1, group1-item2" | "group2-item1" | empty | empty)
     * becomes
     * [
     *   [group1-item1, group1-item2],
     *   [group2-item1],
     *   etc
     * ]
     * example 2:
     * ("group1-item1" | "group2-item1, group2-item2" | empty | empty)
     * becomes
     *   [group1-item1],
     *   [group2-item1, group2-item2],
     *   etc
     * ]
     */
    importedGroups.map(importedGroup => {
      const importedGroupGroups = importedGroup
        .split(/,(?=(?:[^"]*"[^"]*")*[^"]*$)/)
        .map(group => group
          .replace(/^"|"$/g, "")
          .trim() // trims leading and trailing whitespace(s)
        )
        .filter(group => !!group)
      if (importedGroupGroups.length) {
        rawGroups.push(importedGroupGroups)
      }
    })

    /**
     * populate array by copying last item in each array to make all arrays at least the size of the following array
     *
     * example 1: no changes
     * example 2:
     * [
     *   [group1-item1, group1-item1],
     *   [group2-item1, group2-item2],
     *   etc
     * ]
     */
    let diffCount = 0
    if (rawGroups[3]) {
      if (rawGroups[3].length > rawGroups[2].length) {
        if (!diffCount) diffCount = rawGroups[3].length - rawGroups[2].length
        for (let i = rawGroups[2].length - 1; i < diffCount; i++) {
          rawGroups[2].push(rawGroups[2][i])
        }
      }
    }
    if (rawGroups[2]) {
      if (rawGroups[2].length > rawGroups[1].length) {
        if (!diffCount) diffCount = rawGroups[2].length - rawGroups[1].length
        for (let i = rawGroups[1].length - 1; i < diffCount; i++) {
          rawGroups[1].push(rawGroups[1][i])
        }
      }
    }
    if (rawGroups[1]) {
      if (rawGroups[1].length > rawGroups[0].length) {
        if (!diffCount) diffCount = rawGroups[1].length - rawGroups[0].length
        for (let i = rawGroups[0].length - 1; i < diffCount; i++) {
          rawGroups[0].push(rawGroups[0][i])
        }
      }
    }

    const groups: string[][] = []

    /**
     * combine and distribute rawGroup arrays
     *
     * result will have arrays grouped by item numbers
     * (building on examples from above)
     * example 1:
     * [
     *   [group1-item1, group2-item1].
     *   [group1-item2],
     *   etc
     * ]
     * example 2, previous group-item can carry over if omitted:
     * [
     *   [group1-item1, group2-item1],
     *   [group1-item1, group2-item2],
     *   etc
     * ]
     */
    if (rawGroups[0]) { // first terms
      for (const index in rawGroups[0]) { // each first term
        groups.push([rawGroups[0][index]]) // start new group for each term in rowGroups[0]
      }
    }
    if (rawGroups[1]) { // second terms
      for (const index in rawGroups[1]) { // each second term
        groups[index].push(rawGroups[1][index])
        // add each term in rawGroups[1] to each corresponding group as second term
      }
    }
    if (rawGroups[2]) {
      for (const index in rawGroups[2]) { // each third term
        groups[index].push(rawGroups[2][index])
        // add each term in rawGroups[2] to each corresponding group as third term
      }
    }
    if (rawGroups[3]) {
      for (const index in rawGroups[3]) { // each fourth term
        groups[index].push(rawGroups[3][index])
        // add each term in rawGroups[3] to each corresponding group as fourth term
      }
    }

    return groups
  }

}
