import { createFeature, createReducer, on } from "@ngrx/store"
import { StripeActions } from "./stripe.actions"
import { FeatureKey, State } from "./stripe.model"

const initialState: State = {
  customerId: null
}

export const stripeReducer = createReducer(
  initialState,
  on(StripeActions.setCustomerId, (state, { customerId }) => ({
    ...state,
    customerId
  })),

  on(StripeActions.loadStripes, state => state),
  on(StripeActions.loadStripesSuccess, (state, action) => state),
  on(StripeActions.loadStripesFailure, (state, action) => state),
)

export const stripeFeature = createFeature({
  name: FeatureKey,
  reducer: stripeReducer,
})
