import { Field } from "../../fields/fields.interface"
import { MenuItem } from "primeng/api"

export const FeatureKey = "content"

export interface State {
  groupDocs: ContentDocs
  groups: Groups
  itemDocs: ContentDocs
  items: Items
  menuDocs: ContentDocs
  menuItems: MenuItem[]
  menuItemDocs: ContentDocs
}

export enum ContentType {
  PAGE = "PAGE",
  ROOT_GROUP = "ROOT_GROUP",
  GROUP = "GROUP",
  PRODUCT = "PRODUCT",
  ITEM = "ITEM",
  MENU = "MENU",
}

export interface Item {
  docId: string
  description: string
  images: string[]
  manufacturer: string
  retail: number
  sku: string // unique id
  title: string
  unit: string
}

export interface Group {
  docId: string
  groupRefs: string[] // by sku
  itemRefs: string[] // by sku
  description: string
  groups: string[]
  images: string[]
  sku: string // unique id
  title: string
}

export interface Groups {
  [sku: string]: Group
}

export interface Items {
  [sku: string]: Item
}

export interface ContentDocs {
  [contentDocId: string]: ContentDoc
}

export interface ContentDoc {
  content: Groups | Items | MenuItem[]
  id: string // docId
  type: ContentType
}


export interface Content {
  id: string // contentId
  fieldGroups: {
    fields: Field[]
  }[]
  status: Status
  type: ContentType
  // version: Version
}


export enum Status {
  PUBLISHED = "P",
  UNPUBLISHED = "U",
  DELETED = "D",
}

export interface SetDataOptions {
  cache: boolean
}
