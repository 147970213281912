import { createActionGroup, emptyProps, props } from "@ngrx/store"
import { Content, ContentDoc, ContentDocs, Group, Groups, Item, Items, SetDataOptions } from "./content.model"
import { MenuItem } from "primeng/api"

export const ContentActions = createActionGroup({
  source: "Content",
  events: {
    "init": emptyProps(),

    "get menu content": emptyProps(),
    "get groups content": emptyProps(),
    "get items content": emptyProps(),

    "set menu items from firestore": props<{ menuItems: MenuItem[], options: SetDataOptions }>(),
    "set groups content from firestore": props<{ groups: Groups, options: SetDataOptions }>(),
    "set items content from firestore": props<{ items: Items, options: SetDataOptions }>(),

    "build breadcrumbs": props<{ pathSegments: string[] }>(),
    "set breadcrumbs": props<{ breadcrumbs: MenuItem[] }>(),

    "Content Get Groups": emptyProps(),
    "Content Get Menus": emptyProps(),
    "Content Get Page": props<{ pageId: string | null }>(),
    "Content Get Pages": emptyProps(),
    "Content Get Items":emptyProps(),

    "Content Set Menus": props<{ content: ContentDoc["content"] }>(),
    "Content Set Page": props<{ content: Content | null }>(),
    "Content Set Pages": props<{ content: ContentDoc["content"] }>(),

    "Content Set Group Docs": props<{ contentDocs: ContentDocs }>(),
    "Content Set Menu Docs": props<{ contentDocs: ContentDocs }>(),
    "Content Set Page Docs": props<{ contentDocs: ContentDocs }>(),
    "Content Set Item Docs": props<{ contentDocs: ContentDocs }>(),

    "Content Get Group Docs": emptyProps(),
    "Content Get Menu Docs": emptyProps(),
    "Content Get Page Docs": emptyProps(),
    "Content Get Item Docs": emptyProps(),

    "Content Edit": props<{ content: Content }>(),
    // "Content Save": props<{ previousCategory: Category, newCategory: Category, allCategories: Category[] }>(),
    "Content Remove": props<{ id: string }>(),

    "Content Purge": emptyProps(),
    "Content Import": emptyProps(),

    "saved": emptyProps(),
    "failed": emptyProps(),
  }
})
