import { createFeatureSelector, createSelector } from "@ngrx/store"
import { getRouterSelectors, RouterReducerState } from "@ngrx/router-store"
import { RouterState } from "./route-serializer"
import { has } from "lodash-es"

export const ROUTER_FEATURE_KEY = "router"

const getRouterState = createFeatureSelector<RouterReducerState<RouterState>>(ROUTER_FEATURE_KEY)
export const {
  selectQueryParams, // select the current route query params
  selectQueryParam, // factory function to select a query param
  selectRouteParams, // select the current route params
  selectRouteParam, // factory function to select a route param
  selectRouteData, // select the current route data
  selectUrl, // select the current url
} = getRouterSelectors(getRouterState)

export const getGroupId = createSelector(getRouterState, (routerState) =>
  routerState?.state?.groupId || "")
export const getPathSegments = createSelector(getRouterState, (routerState) =>
  routerState?.state?.pathSegments)

export const getRouterData = createSelector(getRouterState, (state) =>
  state.state.data)
export const getRouterQueryParams = createSelector(getRouterState, (state) =>
  state?.state?.queryParams || {})

export const isUrlStartsWith = (startsWith: string) => createSelector(selectUrl, (url) =>
  url.startsWith(startsWith))
export const getBreadcrumbData = createSelector(getRouterData, (data) =>
  data?.breadcrumb || [])
export const getShowGiftImage = createSelector(getRouterData, (data) =>
  !!data?.showImage)

export const getExistQueryParam = (name: string) => createSelector(getRouterQueryParams, (queryParams) =>
    has(queryParams, name))

export const getQueryExample = createSelector(
  getExistQueryParam("example"),
  getRouterQueryParams,
  (promoCode, queryParams) => promoCode ? queryParams["example"] : "",
)

export const getSearchQuery = createSelector(getRouterState, (routerState) => {
  return routerState && routerState.state ? routerState.state.queryParams : {}
})
