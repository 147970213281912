import { ChangeDetectionStrategy, Component, inject } from "@angular/core"
import { AuthService } from "../../services/auth.service"
import { Store } from "@ngrx/store"
import * as AppStore from "../../store"
import { HlmButtonDirective } from "@spartan-ng/ui-button-helm"
import { InheritDirective } from "../../directives/inherit.directive"

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    HlmButtonDirective,
    InheritDirective,
  ],
  standalone: true,
  template: `
    <div style="font-size: 18px; text-align: center">
      {{ userName() }}...
    </div>
    <button
      hlmBtn
      variant="red"
      size="medium"
      (click)="signOut($event)"
    >
      Log Out
    </button>
  `,
  selector: "e2e-login-logged-in",
})
export class LoginLoggedInComponent {
  private authService = inject(AuthService)
  private store = inject(Store<AppStore.State>)

  userName = this.store.selectSignal(AppStore.userName)

  signOut(event: Event) {
    event.preventDefault()
    this.authService.signOut()
  }

}
