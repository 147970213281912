import { ContentDoc, ContentType, Group, Item } from "../store/content/content.model"
import { MenuItem } from "../services/firestore-read.service"


export function dataTransform(contentDoc: ContentDoc) {
  const transformedContent_map = new Map<string | number, Group | Item | MenuItem[]>()
  switch (contentDoc.type) {
    case ContentType.GROUP:
    case ContentType.ITEM:
      Object.values(contentDoc.content).forEach((content: Group | Item) => {
        // Add docId to each content in this doc
        const transformedContent: Group | Item = {
          ...content,
          docId: contentDoc.id,
        }

        transformedContent_map.set(content.sku, transformedContent)
      })
      break
    case ContentType.MENU:
      Object.values(contentDoc.content).forEach((content: MenuItem[], index: number) => {
        transformedContent_map.set(index, content)
      })

  }

  return {
    ...contentDoc,
    content: Object.fromEntries(transformedContent_map)
  }
}
